import React, { useContext, useEffect, useState } from 'react';

import adduser from 'api/add-user';

import Password from 'components/User/Password';
import CommandOutputContainer from 'components/CommandOutputContainer';
import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

import AppContext from 'context/App';
import CommandContext from 'context/Command';

export default ({ user }) => {
    const { setUser } = useContext(AppContext);
    const { print, exit } = useContext(CommandContext);
    const [password, setPassword] = useState(undefined);
    const [confirmPassword, setConfirmPassword] = useState(undefined);

    useEffect(() => {
        if (password === undefined || confirmPassword === undefined) return;

        if (password !== confirmPassword) {
            print(<Error>passwords do not match</Error>);
            exit();
            return;
        }

        if (password === confirmPassword) {
            adduser({
                email: user,
                password,
            })
                .then(({ token }) => {
                    setUser({
                        email: user,
                        token,
                    });
                    print(<Success>adduser success!</Success>);
                    exit();
                })
                .catch(() => {
                    print(<Error>adduser failed</Error>);
                    exit();
                });
        }
    }, [password, confirmPassword, print, exit, user, setUser]);

    return (
        <CommandOutputContainer>
            <Password setPassword={setPassword} />
            {password && (
                <Password
                    passwordText="confirm password"
                    setPassword={setConfirmPassword}
                />
            )}
        </CommandOutputContainer>
    );
};
