const TYPES = {
    UPDATE_INPUTS: 'UPDATE_INPUTS',
    UPDATE_CURSOR: 'UPDATE_CURSOR',
};

export const updateCursorAndInputs = ({ inputs, cursorPosition }) => ({
    type: TYPES.UPDATE_INPUTS,
    payload: {
        inputs,
        cursorPosition,
    },
});

export const updateCursor = (position) => ({
    type: TYPES.UPDATE_CURSOR,
    payload: position,
});

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.UPDATE_INPUTS:
            return {
                ...state,
                cursorPosition: action.payload.cursorPosition,
                inputs: action.payload.inputs,
            };
        case TYPES.UPDATE_CURSOR:
            return {
                ...state,
                cursorPosition: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
