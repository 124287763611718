import { keyCodes } from 'hooks/constants';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { colors } from 'styleguide';

type Props = {
    showModal: boolean;
    onClose: () => void;
};

export default ({ showModal, onClose, children }: PropsWithChildren<Props>) => {
    useEffect(() => {
        if (!showModal) {
            return;
        }

        const initial = document.body.style.overflow;
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = initial;
        };
    }, [showModal]);

    useKeydown((e: any) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.ESC) {
            onClose();
        }
    });

    if (!showModal) {
        return null;
    }

    return createPortal(
        <>
            <Overlay onClick={() => onClose()}>
                <ModalContainer
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {children}
                </ModalContainer>
            </Overlay>
        </>,
        document.body
    );
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    &::before {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: ${colors.purpleBaliHai};
        opacity: 0.3;
        z-index: 9998;
        content: '';
    }

    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    background-color: ${colors.blueCharade};

    height: 350px;
    width: 650px;

    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 6px black;
    padding: 4px;

    z-index: 9999;
`;
