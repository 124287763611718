import euclideanDistance from './euclidean-distance';

const randomColorValue = () => Math.floor(Math.random() * 256);

// k-means function that assumes we're operating on colors
export default ({ colorVectors, means }) => {
    let centroids = [];
    let delta = Number.MAX_VALUE;
    const epsilon = 0.0001;

    const clusters = (() => {
        const tmp = [];
        for (let i = 0; i < means; i++) {
            tmp.push([]);
        }
        return tmp;
    })();

    for (let i = 0; i < means; i++) {
        centroids.push([
            randomColorValue(),
            randomColorValue(),
            randomColorValue(),
        ]);
    }

    while (delta > epsilon) {
        // iterate over all the points and start converging on centroids
        clusters.forEach((v, idx) => (clusters[idx] = []));
        for (let i = 0; i < colorVectors.length; i++) {
            const point = colorVectors[i];
            const distances = centroids.map((centroid) =>
                euclideanDistance(point, centroid)
            );
            const minIdx = distances.reduce(
                (accum, val, idx) => (val <= distances[accum] ? idx : accum),
                0
            );

            clusters[minIdx].push(point);
        }

        // for each cluster compute the new average (centroid)
        // console.log(clusters);
        const newCentroids = clusters.map((cluster) => {
            if (cluster.length === 0)
                return [
                    randomColorValue(),
                    randomColorValue(),
                    randomColorValue(),
                ];

            const sum = cluster.reduce((accum, val) => [
                accum[0] + val[0],
                accum[1] + val[1],
                accum[2] + val[2],
            ]);
            return [
                sum[0] / cluster.length,
                sum[1] / cluster.length,
                sum[2] / cluster.length,
            ];
        });

        // console.log(newCentroids);
        // compute delta between newCentroid and oldCentroid
        delta = Math.max(
            ...centroids.map((centroid, idx) =>
                euclideanDistance(centroid, newCentroids[idx])
            )
        );

        centroids = newCentroids;
    }

    const centroidsWithClusterSize = clusters.map((cluster, idx) => [
        cluster.length,
        centroids[idx],
        cluster,
    ]);
    centroidsWithClusterSize.sort((left, right) => right[0] - left[0]);

    // returns [ centroids, clusters ]
    return [
        centroidsWithClusterSize.map((x) => x[1]),
        centroidsWithClusterSize.map((x) => x[2]),
    ];
};
