import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import DragAndDropZone from 'components/DragAndDropZone';
import FileViewer from 'components/FileViewer';
import kMeans from 'math/k-means';
import { colors } from 'styleguide';

import SettingsPrompt from './SettingsPrompt';

const SilhouetteWorker = new Worker(
    new URL('workers/silhouette.worker.js', import.meta.url)
);

const ImagePalette = ({ returnToTerminal, maxWidth = 100 }) => {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [prcImage, setPrcImage] = useState(null);
    const [palette, setPalette] = useState(null);
    const [clusters, setClusters] = useState(null);
    const [silhouettes, setSilhouettes] = useState(null);
    const [means, setMeans] = useState(5);
    const workerRef = useRef(null);

    const resetView = () => {
        setFile(null);
        setImage(null);
        setPrcImage(null);
        setPalette(null);
        setClusters(null);
        setSilhouettes(null);
    };

    useEffect(() => {
        if (!file) return;

        const reader = new FileReader();

        reader.onload = ({ target: { result } }) => {
            const img = new Image();
            img.src = result;
            img.onload = function resizeIfNeeded() {
                if (this.width <= maxWidth) {
                    return setPrcImage(this);
                }

                const imgToProc = new Image();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const ratio = this.height / this.width;
                canvas.width = maxWidth;
                canvas.height = maxWidth * ratio;
                ctx.drawImage(this, 0, 0, maxWidth, maxWidth * ratio);

                imgToProc.src = canvas.toDataURL();
                setPrcImage(imgToProc);
            };

            setImage(img);
        };

        reader.readAsDataURL(file);
    }, [file, maxWidth]);

    useEffect(() => {
        if (!prcImage) return;

        // get the image data in a useable format
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(prcImage, 0, 0, prcImage.width, prcImage.height);
        const imageData = ctx.getImageData(
            0,
            0,
            prcImage.width,
            prcImage.height
        );

        // convert it to an array of rgb vectors
        const colorVectors = imageData.data.reduce((accum, value, idx, arr) => {
            if (idx % 4 > 0) return accum;

            accum.push([value, arr[idx + 1], arr[idx + 2]]);
            return accum;
        }, []);

        const result = kMeans({
            colorVectors,
            means,
        });
        setPalette(result[0]);
        setClusters(result[1]);
    }, [prcImage, means]);

    useEffect(() => {
        // compute silhouette
        if (!clusters) return;

        workerRef.current = SilhouetteWorker;
        workerRef.current.postMessage(clusters);

        workerRef.current.onmessage = (e) => setSilhouettes(e.data);

        return () => {
            workerRef.current.terminate();
            workerRef.current = undefined;
        };
    }, [clusters]);

    return (
        <StyledFileViewer
            fileName="image-palette.sample"
            returnToTerminal={returnToTerminal}
        >
            {!file && <DragAndDropZone setFile={setFile} />}
            {image && (
                <Container>
                    <ImageContainer>
                        <StyledImage alt="base-for-colors" src={image.src} />
                        <LoadNewButton onClick={() => resetView()}>
                            load a new image
                        </LoadNewButton>
                    </ImageContainer>
                    <PaletteAndSettingsContainer>
                        <PaletteContainer>
                            {palette &&
                                palette
                                    .filter((color) => color.length === 3)
                                    .map((color, idx) => (
                                        <PaletteColorAndSilhouette key={idx}>
                                            <PaletteColor color={color} />
                                            <Silhouette>
                                                {silhouettes ? (
                                                    <SilhouetteSpan>
                                                        silhouette
                                                        <br />
                                                        {silhouettes[
                                                            idx
                                                        ].toFixed(3)}
                                                    </SilhouetteSpan>
                                                ) : (
                                                    <ItalicSpan>
                                                        processing silhouette
                                                    </ItalicSpan>
                                                )}
                                            </Silhouette>
                                        </PaletteColorAndSilhouette>
                                    ))}
                            {palette && silhouettes && (
                                <SilhouetteAvg>
                                    silhouette avg:
                                    {(
                                        silhouettes.reduce((a, s) => a + s) /
                                        silhouettes.length
                                    ).toFixed(3)}
                                </SilhouetteAvg>
                            )}
                            {!palette && <span>determining palette...</span>}
                        </PaletteContainer>
                        <SettingsContainer>
                            {/* TODO: add help command to the input */}
                            <SettingsDescription>
                                Commands
                                <Setting>
                                    means &lt;number&gt; -- sets the number of
                                    clusters to group by
                                </Setting>
                            </SettingsDescription>
                            <SettingsPrompt
                                isActive={true}
                                setMeans={(num) => {
                                    if (num === means) return;
                                    setSilhouettes(null);
                                    setClusters(null);
                                    setPalette(null);
                                    setMeans(num);
                                }}
                            />
                        </SettingsContainer>
                    </PaletteAndSettingsContainer>
                </Container>
            )}
        </StyledFileViewer>
    );
};

const StyledFileViewer = styled(FileViewer)`
    height: 100%;
`;

const Container = styled.div`
    display: flex;

    width: 100%;
    height: 100%;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    justify-content: center;
    align-items: center;

    padding: 18px;

    border: 1px solid ${colors.frenchGray};
`;

const StyledImage = styled.img`
    max-height: 90%;
    object-fit: contain;
`;

const LoadNewButton = styled.div`
    margin-top: 8px;
    cursor: copy;
`;

const PaletteAndSettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
`;

const PaletteContainer = styled.div`
    display: flex;
    position: relative;

    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    height: 60%;

    border: 1px solid ${colors.frenchGray};

    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
`;

const PaletteColorAndSilhouette = styled.div`
    display: flex;
    min-width: 220px;
`;

const Silhouette = styled.div`
    display: flex;
    align-items: center;
    padding-left: 16px;
`;

const SilhouetteAvg = styled.div`
    position: absolute;
    bottom: 24px;
    right: 24px;
`;

const SilhouetteSpan = styled.span`
    line-height: 1.4;
`;

const SettingsDescription = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
`;

const Setting = styled.div`
    margin-top: 4px;
    margin-left: 12px;
`;

const ItalicSpan = styled.span`
    font-style: italic;
`;

const PaletteColor = styled.div`
    width: 80px;
    height: 80px;
    background-color: rgb(${(p) => p.color.join(',')});
`;

const SettingsContainer = styled.div`
    height: 40%;
    border: 1px solid ${colors.frenchGray};

    padding: 12px;
`;

export default ImagePalette;
