import Login from 'components/User/Login';
import parseUser from 'programs/utils/parse-user';

const login = {
    run: ({ input, print, exit }) => {
        const user = parseUser({
            input,
            command: 'login',
            print,
            exit,
        });

        if (!user) return exit();

        return print(<Login user={user} />);
    },
};

export default login;
