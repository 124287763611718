const images = [
    {
        alt: 'bakery',
        id: '1UTzNgde2EHHwxounmh7xhj884i7w2ain',
    },
    {
        alt: 'lady in yellow',
        id: '1G1U4xaM4TRpABjZxTI-EFM6QZDsDCDco',
    },
    {
        alt: 'bushwick sunset',
        id: '1G-C-iRsQ-OvNywReSeBAm4cjh6onoeaJ',
    },
    {
        alt: 'grand canyon west 1',
        id: '1pjoQdkBx1iUCiBPGILgMhFYM3gP4HM-I',
    },
    {
        alt: 'grand canyon hut',
        id: '1iGHDMTZd9GFjbgtSPuIj8rPnVTKYD2vx',
    },
    {
        alt: 'grand canyon west 2',
        id: '1eX1RM76kv8T4sybQfPiDSenNDwnQ4UyG',
    },
    {
        alt: 'man on bike',
        id: '1GipTrMQa855tx-18hviEsX-CAwbnviEC',
    },
    {
        alt: 'bikes locked up',
        id: '1DhmKnNHGuAsFrMIWfu1BZdmmt_3B4ASv',
    },
    {
        alt: 'truck and stairs',
        id: '13P810f1TlWpqg-p_ueMpwwIXXIRUeYBn',
    },
    {
        alt: 'cinque terre sunset 1',
        id: '0B_6m96ciKwVVcjJZWHlSN2p6Mjg',
    },
    {
        alt: 'cinque terre sunset 2',
        id: '0B_6m96ciKwVVRXdQZ21PV2lJY28',
    },
    {
        alt: 'cinque terre sunset 3',
        id: 'https://drive.google.com/open?id=0B_6m96ciKwVVNlNkZzJUdFFuVFk',
    },
    {
        alt: 'man standing grand canyon',
        id: '1HJY_slJ13PiVcI-UbuvUsGJfl8VG5CdM',
    },
    {
        alt: 'banksy accident',
        id: '1CiH8TVKqRJpknadWrTbtGeoKFNnDtn2h',
    },
    {
        alt: 'grand canyon blue',
        id: '1Z3nY1JuUmt0RCIdfQGxT2wcEzX2bQAYi',
    },
    {
        alt: 'yellow plant',
        id: '19kR4HJSm1dHfjmRSD0b08G25l7EA5OoH',
    },
    {
        alt: 'riviera lights',
        id: '1ByYTBFlae5Fk4idiEX65uUtd1t-b7SOn',
    },
    {
        alt: 'teepee',
        id: '1Q0sjlwUhxt-c7g73ZMyvMjJuVHIExDN2',
    },
];

module.exports = images;
