import React, { useState } from 'react';
import styled from 'styled-components';
import googleImageUrl from './google-image-url';

const Thumbnail = ({ alt, id, onClick }) => {
    const [blurLoaded, setBlurLoaded] = useState(false);
    const [lowResLoaded, setLowResLoaded] = useState(false);
    const [fullLoaded, setFullLoaded] = useState(false);

    return (
        <Container onClick={onClick}>
            <GreyThumb loaded={blurLoaded || lowResLoaded || fullLoaded} />
            <ThumbnailImage
                alt={alt}
                src={googleImageUrl(id, true)}
                loaded={blurLoaded && !lowResLoaded && !fullLoaded}
                onLoad={() => setBlurLoaded(true)}
                className="placeholder"
            />
            <ThumbnailImage
                alt={alt}
                src={googleImageUrl(id, true, 750, 100)}
                loaded={lowResLoaded && !fullLoaded}
                onLoad={() => setLowResLoaded(true)}
                className="placeholder no-blur"
            />
            <ThumbnailImage
                alt={alt}
                src={googleImageUrl(id, false)}
                loaded={fullLoaded}
                lowResLoaded={lowResLoaded}
                onLoad={() => setFullLoaded(true)}
            />
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;

    align-items: center;
    scroll-snap-align: center;

    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    &:after {
        content: '';
        padding-bottom: 100%;
        display: block;
    }
`;

const ThumbnailImage = styled.img`
    opacity: ${(p) => (p.loaded ? '1' : '0')};
    width: 100%;

    object-fit: contain;

    cursor: pointer;
    transition: ${(p) => (p.lowResLoaded ? 'none' : 'opacity')} 250ms linear;

    &.placeholder {
        position: absolute;
        left: 0;
        object-fit: contain;
        filter: blur(50px);
        transform: scale(1);
    }

    &.placeholder.no-blur {
        transition: opacity 250ms linear !important;
        filter: initial;
    }

    &:after {
        content: '';
        padding-bottom: 100%;
        display: block;
    }

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const GreyThumb = styled.div`
    opacity: ${(p) => (p.loaded ? 0 : 100)};
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #f6f6f6;
    transition: opacity 250ms linear;

    &:after {
        content: '';
        padding-bottom: 100%;
        display: block;
    }
`;

export default Thumbnail;
