import File, { FileTypes } from '../File';
import Users from './Users';

const root = new File({
    fileType: FileTypes.DIRECTORY,
    name: '/',
    parent: () => null,
    children: () => [Users],
});

export default root;
