import { useCallback, useEffect, useReducer } from 'react';
import { keyCodes } from '../constants';
import useKeydown from '../KeyEvent/useKeydown';
import reducer, {
    updateCursor,
    updateCursorAndInputs,
} from './cursorInputReducer';

const useInputWithCursor = ({ isActive, handleSubmit }) => {
    const [{ inputs, cursorPosition }, dispatch] = useReducer(reducer, {
        inputs: [],
        cursorPosition: 0,
    });

    const isMobile = window.mobilecheck();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateInputs = useCallback(
        (newInput, diffOverride = undefined) => {
            let diff;
            if (diffOverride === undefined) {
                diff = newInput.length - inputs.length;
            } else {
                diff = diffOverride;
            }

            dispatch(
                updateCursorAndInputs({
                    inputs: newInput,
                    cursorPosition: cursorPosition + diff,
                })
            );
        },
        [inputs, cursorPosition]
    );

    const handleKey = (keys, handler) => (key) => {
        if ((keys.has && keys.has(key)) || keys === key) handler(key);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDelete = useCallback(
        handleKey(keyCodes.DELETE, () => {
            const sansEnd = [
                ...inputs.slice(0, cursorPosition),
                ...inputs.slice(cursorPosition + 1),
            ];

            updateInputs(sansEnd, 0);
        }),
        [inputs, cursorPosition]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleBackspace = useCallback(
        handleKey(keyCodes.BACKSPACE, () => {
            const sansEnd = [
                ...inputs.slice(0, cursorPosition - 1),
                ...inputs.slice(cursorPosition),
            ];

            updateInputs(sansEnd);
        }),
        [inputs, cursorPosition]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLeftRight = useCallback(
        handleKey(new Set().add(keyCodes.LEFT).add(keyCodes.RIGHT), (key) => {
            const direction = key - keyCodes.UP;
            const cursorIdx = Math.max(
                Math.min(cursorPosition + direction, inputs.length),
                0
            );

            dispatch(updateCursor(cursorIdx));
        }),
        [inputs, cursorPosition]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleEnter = useCallback(handleKey(keyCodes.ENTER, handleSubmit), [
        handleSubmit,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePaste = useCallback(
        async (e, key) => {
            const eventObj = window.event ? window.event : e;

            // V + (ctrl or cmd)
            if (key === 86 && (eventObj.ctrlKey || eventObj.metaKey)) {
                if (!navigator.clipboard) return;

                const text = await navigator.clipboard.readText();
                updateInputs([...inputs, ...text]);
            }
        },
        [inputs, updateInputs]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const keyDownHandler = useCallback(
        async (e) => {
            const key = e.charCode || e.keyCode;

            if (!isMobile) {
                await handlePaste(e, key);
                handleDelete(key);
                handleBackspace(key);
                handleLeftRight(key);
            }

            handleEnter(key);
        },
        [
            isMobile,
            handleEnter,
            handlePaste,
            handleDelete,
            handleBackspace,
            handleLeftRight,
        ]
    );

    useKeydown(keyDownHandler, isActive);

    // prettier-ignore
    useEffect(() => {
        if (!isActive || isMobile) return;

        const keypressHandler = e => {
            if (e.keyCode === keyCodes.ENTER) return;

            const char = String.fromCharCode(e.charCode);

            const newInputs = [
                ...inputs.slice(0, cursorPosition),
                char,
                ...inputs.slice(cursorPosition),
            ];
            updateInputs([...newInputs]);
        };

        window.addEventListener('keypress', keypressHandler, true);

        return () =>
            window.removeEventListener('keypress', keypressHandler, true);
    }, [inputs, cursorPosition, isActive, updateInputs, isMobile]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mobileInputHandler = useCallback(
        (e) => {
            updateInputs([...e.target.value]);
        },
        [updateInputs]
    );

    return {
        cursorPosition,
        inputs,
        updateInputs,
        mobileInputHandler,
    };
};

export default useInputWithCursor;
