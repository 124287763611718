import { useState } from 'react';
import styled from 'styled-components';

import useInterrupt from 'hooks/KeyEvent/useInterrupt';
import { colors, mediaQueries } from 'styleguide';

import Thumbnail from './Thumbnail';
import googleImageUrl from './google-image-url';
import images from './images';

const PhotoGallery = ({ returnToTerminal }) => {
    const [activeImage, setActiveImage] = useState(null);

    const onClickThumbnail = (alt, id) => () => {
        // TODO: figure out how to animate the full size image
        setActiveImage({
            alt,
            src: googleImageUrl(id, false),
        });
    };

    useInterrupt(returnToTerminal);

    return (
        <Container>
            <LeftSidebar>
                <div>
                    <p>
                        moldy
                        <i>analog</i>
                    </p>
                    <br />
                    <span>hasselblad 500cm</span>
                    <span>leica m6</span>
                </div>
                <span>
                    |{' '}
                    <a
                        href="https://instagram.com/moldyanalog"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        instagram
                    </a>
                </span>
            </LeftSidebar>
            <GalleryThumbnails>
                {images.map(({ alt, id }) => (
                    <Thumbnail
                        key={id}
                        alt={alt}
                        onClick={onClickThumbnail(alt, id)}
                        id={id}
                    />
                ))}
            </GalleryThumbnails>
            {activeImage && (
                <ActiveImage onClick={() => setActiveImage(null)}>
                    <img alt={activeImage.alt} src={activeImage.src} />
                </ActiveImage>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;

    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    height: 100vh;

    background-color: white;

    padding: 32px 24px;
    padding-right: 0px;

    ${mediaQueries.mobile`
        flex-direction: column;
    `};
`;

const LeftSidebar = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 285px;
    position: relative;

    ${mediaQueries.mobile`
        flex-direction: row;
        flex: 0 0 auto;
        padding-bottom: 48px;
        margin-right: 24px;
    `};

    div {
        display: flex;
        flex-direction: column;
    }

    p {
        font-size: 20px;
        color: ${colors.greyMineShaft};

        & > i {
            font-size: 16px;
        }
    }

    span {
        font-size: 14px;
        color: ${colors.greyMineShaft};
    }

    &:after {
        content: '';
        position: absolute;
        right: 24px;
        width: 1px;
        height: 100%;
        background-color: ${colors.frenchGray};

        ${mediaQueries.mobile`
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 24px;
            right: initial;
        `};
    }

    & > span:last-child {
        margin-top: auto;
        width: 100%;

        ${mediaQueries.mobile`
            margin-top: 4px;
            margin-left: auto;
            height: 100%;
            width: initial;
        `} &:before {
            position: relative;
            content: '🍄';
            top: 2px;
        }
    }
`;

const GalleryThumbnails = styled.div`
    display: grid;
    flex: 1 1 auto;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100%;
    grid-gap: 16px;
    grid-auto-flow: dense;
    align-items: center;

    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    padding-right: 24px;

    ${mediaQueries.tabletAndMobile`
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(100%, 1fr);
    `};
`;

const ActiveImage = styled.div`
    display: flex;
    position: absolute;

    height: calc(100% - 64px);
    width: calc(100% - 48px);

    z-index: 2;
    justify-content: center;

    img {
        max-height: 100%;

        object-fit: contain;
        cursor: pointer;
        z-index: 3;

        filter: drop-shadow(0 -4px 0 white) drop-shadow(0 4px 0 white)
            drop-shadow(-4px 0 0 white) drop-shadow(4px 0 0 white);
    }

    &:after {
        content: '';
        position: fixed;
        height: calc(100vh + 1px);
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1;

        background-color: rgba(0, 0, 0, 0.8);
    }
`;

export default PhotoGallery;
