import React from 'react';
import styled from 'styled-components';
import * as GlobalStyles from './globalStyles';

// styled main dom element
// used as the a wrapper around page views to set up proper margins as well as font families
class Page extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <GlobalStyles.DefaultGlobals />
                <GlobalStyles.DefaultFonts />
                <GlobalStyles.DefaultStyles />
                <StyledMain {...this.props} />
            </React.Fragment>
        );
    }
}

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    min-height: 100vh;
    height: ${(p) => p.pageHeight && `${p.pageHeight}px`};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: auto;
    margin-right: auto;
    > * {
        flex-shrink: 0;
    }
`;

export default Page;
