import File, { FileTypes } from 'files/File';
import moldy530 from '..';
import PhotoGallery from './gallery';

export default new File({
    fileType: FileTypes.DIRECTORY,
    name: 'Photographer',
    parent: () => moldy530,
    children: () => [PhotoGallery],
});
