import React, { useState, useEffect, useContext } from 'react';

import Password from '../Password';

import login from 'api/login';

import CommandOutputContainer from 'components/CommandOutputContainer';
import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

import AppContext from 'context/App';
import CommandContext from 'context/Command';

const Login = ({ user }) => {
    const { setUser } = useContext(AppContext);
    const { exit, print } = useContext(CommandContext);
    const [password, setPassword] = useState(null);

    useEffect(() => {
        if (password !== null) {
            login({
                email: user,
                password,
            })
                .then(({ token }) => {
                    setUser({
                        email: user,
                        token,
                    });
                    print(<Success>login success!</Success>);
                    exit();
                })
                .catch((e) => {
                    print(<Error>{e.message}</Error>);
                    exit();
                });
        }
    }, [exit, password, print, setUser, user]);

    return (
        <CommandOutputContainer>
            <Password setPassword={setPassword} />
        </CommandOutputContainer>
    );
};

export default Login;
