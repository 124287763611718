import styled from 'styled-components';

import useKeydown from 'hooks/KeyEvent/useKeydown';
import { colors, sizes } from 'styleguide';

const FileViewer = ({
    returnToTerminal,
    children,
    fileName,
    className = undefined,
}) => {
    useKeydown((e) => {
        const eventObj = window.event ? window.event : e;

        if (eventObj.keyCode === 67 && eventObj.ctrlKey) returnToTerminal();
    });

    return (
        <Container>
            <FileHeader>
                <AppName>FileViewer</AppName>
                <FileName> File: {fileName} </FileName>
                {(!window.mobilecheck() && (
                    <QuitInstruction> Ctrl+C to quit </QuitInstruction>
                )) || (
                    <QuitInstruction onClick={returnToTerminal}>
                        Tap to quit
                    </QuitInstruction>
                )}
            </FileHeader>
            <FileBody className={className}>{children}</FileBody>
        </Container>
    );
};

const Container = styled.div`
    background-color: ${colors.blueCharade};
    color: white;

    display: flex;
    flex-direction: column;
    flex: 1;

    height: 100vh;
    overflow: hidden;

    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
    font-size: ${sizes.promptTextPx}px;
    font-style: normal;

    padding: 16px;
`;

const FileHeader = styled.div`
    display: flex;
    flex: 0 0 22px;

    min-height: fit-content;

    background-color: ${colors.frenchGray};
    color: ${colors.blueCharade};

    padding: 2px 8px;
`;

const AppName = styled.div`
    flex-basis: 33.3%;
`;

const FileName = styled.div`
    flex-basis: 33.3%;
    flex: 1;
    text-align: center;
`;

const QuitInstruction = styled.div`
    flex-basis: 33.3%;
    flex: 1;
    text-align: right;
`;

const FileBody = styled.div`
    overflow: auto;
    padding-top: 24px;
`;

export default FileViewer;
