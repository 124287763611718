import React, {
    useContext,
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react';

import TerminalContext from 'context/Terminal';
import AppContext from 'context/App';
import CommandContext from 'context/Command';

import programs from 'programs';
import Error from 'components/ErrorText';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import useStdOut from 'hooks/StdOut/useStdOut';

const CommandRunnner = ({ input }) => {
    const [running, setRunning] = useState(true);
    const lastRunning = useRef(running);
    const { stdOut, print } = useStdOut();

    const terminalContext = useContext(TerminalContext);
    const appContext = useContext(AppContext);

    const { setHistory, history, printNewPrompt } = terminalContext;

    const exit = useCallback(() => setRunning(false), []);

    // prettier-ignore
    useEffect(() => {
        if (input.indexOf('./') === 0) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            input = input.replace('./', 'open ');
        }

        const command = input.split(' ')[0];

        if (command.trim() === '') {
            return exit();
        }

        try {
            setHistory([...history, input]);

            programs[command]({
                input,
                ...terminalContext,
                ...appContext,
                exit,
                print,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== 'production')
                console.log(`%c${e}`, 'color: red');

            print(<Error>cannot find command: {command}</Error>);
            exit();
        }
    }, [input]);

    // prettier-ignore
    useEffect(() => {
        if (lastRunning.current !== running && running === false) {
            lastRunning.current = running;
            printNewPrompt();
        }
    }, [printNewPrompt, running]);

    useKeydown((eventObj) => {
        if (eventObj.keyCode === 67 && eventObj.ctrlKey) exit();
    }, running);

    const contextProps = {
        running,
        print,
        exit,
    };

    return (
        <CommandContext.Provider value={contextProps}>
            {stdOut.map((e, i) => (
                <div key={i}>{e}</div>
            ))}
        </CommandContext.Provider>
    );
};

export default CommandRunnner;
