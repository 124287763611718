import React from 'react';
import Error from 'components/ErrorText';

export default ({ input, command, print, exit }) => {
    const user = input.split(' ')[1];

    if (!user) {
        print(`usage: ${command} <email>`);
        exit();
        return;
    }

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (!emailRegex.test(user)) {
        print(<Error>{user} is not a valid email</Error>);
        exit();
        return;
    }

    return user;
};
