import AddUser from 'components/User/AddUser';
import parseUser from './utils/parse-user';

const adduser = {
    run: ({ input, print, exit }) => {
        const user = parseUser({
            input,
            command: 'adduser',
            print,
            exit,
        });

        if (!user) return exit();

        print(<AddUser user={user} />);
    },
};

export default adduser;
