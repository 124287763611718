import { useContext, useState } from 'react';
import styled from 'styled-components';

import TerminalContext from 'context/Terminal';
import usePromptInput from 'hooks/Prompt/usePromptInput';
import { colors } from 'styleguide';

import MobileInput from 'components/MobileInput';
import TextWithCursor from 'components/TextWithCursor';

const Prompt = () => {
    const { cwd, terminalActive } = useContext(TerminalContext);
    const [isActive, setIsActive] = useState(true);
    // if we don't do this, then each prompt will be updated on a CD, we don't want that
    const [neverChangeCwd] = useState(cwd);

    const { inputs, cursorPosition, mobileInputHandler } = usePromptInput({
        isActive: isActive && terminalActive,
        setIsActive,
    });

    const isMobile = window.mobilecheck();

    const cwdDraw = (() => {
        let tmp = neverChangeCwd;
        let string = `${tmp.name}`;
        tmp = tmp.parent();

        while (tmp) {
            string =
                tmp.name === '/'
                    ? `${tmp.name}${string}`
                    : `${tmp.name}/${string}`;
            tmp = tmp.parent();
        }

        return string;
    })();

    return (
        <PromptContainer>
            <Directory>
                {cwdDraw}
                <BranchWrapper>
                    &nbsp;(
                    <Branch>{process.env.REACT_APP_CURRENT_BRANCH}</Branch>)
                </BranchWrapper>
            </Directory>
            <CommandContainer>
                <CommandFlip>
                    (╯°□°)╯︵
                    <span aria-label="mushroom" role="img">
                        🍄
                    </span>
                </CommandFlip>
                {!isMobile && (
                    <TextWithCursor
                        cursorPosition={cursorPosition}
                        isActive={isActive}
                        text={inputs.join('')}
                    />
                )}
                {isMobile && (
                    <MobileInput
                        inputs={inputs}
                        isActive={isActive && terminalActive}
                        onChange={mobileInputHandler}
                    />
                )}
            </CommandContainer>
        </PromptContainer>
    );
};

const PromptContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
`;

const Directory = styled.div`
    color: ${colors.yellowPicasso};
`;

const Branch = styled.span`
    font-size: 16px;
    color: ${colors.green};
`;

const BranchWrapper = styled.span`
    color: white;
`;

const CommandContainer = styled.div`
    display: flex;
    padding: 4px 0;
`;

const CommandFlip = styled.div`
    color: ${colors.brightTurquoise};
`;

export default Prompt;
