import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const MobileInput = ({ inputs, isActive, onChange, type = undefined }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current && isActive) {
            inputRef.current.focus();
        }
    });

    return (
        <form
            autoComplete={type === 'password' ? undefined : 'off'}
            style={{ width: '100%' }}
            onSubmit={null}
        >
            <StyledInput
                type={type}
                value={inputs.join('')}
                ref={inputRef}
                autocomplete="nope"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                disabled={!isActive}
                onChange={onChange}
            />
        </form>
    );
};

const StyledInput = styled.input`
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;

    color: white;
    white-space: pre;

    font-family: inherit;
    font-size: inherit;
    font-weight: bold;

    margin-top: -3px;
    margin-left: 8px;

    &:focus {
        outline: none !important;
        border: none;
    }
`;

export default MobileInput;
