import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import TerminalContext from 'context/Terminal';
import useTerminalState from 'hooks/Terminal/useTerminalState';
import Mushroom from 'images/Terminal/mushroom.png';
import { colors, sizes } from 'styleguide';

import Greeting from './Greeting';
import Prompt from './Prompt';

const TerminalView = ({ active }) => {
    const initialState = useTerminalState(active);
    const lastElemRef = useRef(null);
    const { stdOut, print } = initialState;

    const scrollToBottom = () => {
        if (lastElemRef.current)
            lastElemRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const ctrlClickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const event = new KeyboardEvent('keydown', {
            ctrlKey: true,
            keyCode: 67,
        });

        window.dispatchEvent(event);
    };

    // prettier-ignore
    useEffect(() => {
        print(<Greeting />);
        print(<Prompt />);
    }, [print]);

    useEffect(() => {
        scrollToBottom();
    });

    return (
        <TerminalContext.Provider value={initialState}>
            <Container active={active}>
                {stdOut.map((elem, idx) => (
                    <StdOutText
                        key={idx}
                        ref={
                            idx === stdOut.length - 1 ? lastElemRef : undefined
                        }
                    >
                        {elem}
                    </StdOutText>
                ))}
                {window.mobilecheck() && (
                    <CtrlCButton onClick={ctrlClickHandler} type="button">
                        ctrl + c
                    </CtrlCButton>
                )}
            </Container>
        </TerminalContext.Provider>
    );
};

const Container = styled.div`
    position: relative;
    display: ${(p) => (p.active ? 'initial' : 'none')};
    flex: 1;

    padding-left: 12px;
    padding-top: 6px;
    padding-right: 12px;

    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
    font-size: ${sizes.promptTextPx}px;
    font-style: normal;

    background-color: ${colors.blueCharade};
    background-image: url(${Mushroom});
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
`;

const CtrlCButton = styled.button`
    position: absolute;
    bottom: 24px;
    right: 24px;
    padding: 3px 6px 6px 6px;

    font-family: inherit;
    font-size: 1.25em;
    color: white;

    background-color: ${colors.greyMineShaft};
    opacity: 1;

    border: none;
    border-radius: 8px;

    &:focus {
        outline: none;
    }

    &:active {
        opacity: 0.5;
    }
`;

const StdOutText = styled.div`
    color: ${colors.frenchGray};
`;

export default TerminalView;
