import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useUser from 'hooks/User/useUser';
import { colors } from 'styleguide';

const Greeting = () => {
    const [drawAnimation, setDrawAnimation] = useState(true);
    const { user } = useUser();

    useEffect(() => {
        const timer = setTimeout(() => setDrawAnimation(false), 7000);
        return clearTimeout(timer);
    }, []);

    if (user) {
        return (
            <GreetingContainer>
                <Container drawAnimation={drawAnimation}>
                    <Fader drawAnimation={drawAnimation} />
                    Welcome back{' '}
                    <ColoredSpan color={colors.yellowPicasso}>
                        {user.email}
                    </ColoredSpan>
                    !
                </Container>
            </GreetingContainer>
        );
    } else {
        return (
            <GreetingContainer>
                <Container drawAnimation={drawAnimation}>
                    <Fader drawAnimation={drawAnimation} />
                    Hi, I&rsquo;m moldy.
                </Container>
                <Container delay={2} drawAnimation={drawAnimation}>
                    <Fader delay={2} drawAnimation={drawAnimation} />
                    This is my portabello shell.
                </Container>
                <Container delay={4} drawAnimation={drawAnimation}>
                    <Fader delay={4} drawAnimation={drawAnimation} />
                    Type{' '}
                    <ColoredSpan color={colors.blueMalibu}>help</ColoredSpan> to
                    get a list of available commands.
                </Container>
            </GreetingContainer>
        );
    }
};

const GreetingContainer = styled.div`
    margin-top: 8px;
    margin-left: 12px;
    margin-bottom: 24px;

    width: fit-content;
`;

const Container = styled.div`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: ${(p) => (p.drawAnimation ? '0' : '18px')};

    @keyframes fadeInTop {
        0% {
            height: 0;
        }
        100% {
            height: 18px;
        }
    }

    animation: ${(p) => (p.drawAnimation ? 'fadeInTop' : 'none')} 0.001s linear;
    animation-fill-mode: forwards;
    animation-delay: ${(p) => p.delay || 0}s;
`;

const Fader = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: ${(p) => (p.drawAnimation ? '100%' : '0%')};

    background-color: ${colors.blueCharade};

    @keyframes fadeInLeft {
        0% {
            width: 100%;
        }
        100% {
            width: 0%;
        }
    }

    animation: ${(p) => (p.drawAnimation ? 'fadeInLeft' : 'none')} 2.5s linear;
    animation-fill-mode: forwards;
    animation-delay: ${(p) => p.delay || 0}s;
`;

const ColoredSpan = styled.span`
    color: ${(p) => p.color};
`;

export default Greeting;
