import { useMutation, useQuery } from '@tanstack/react-query';
import recordMagnetSelection from 'api/movies/record-magnet-selection';
import searchImdb from 'api/movies/search-imdb';
import searchRarbg from 'api/movies/search-rarbg';
import ErrorText from 'components/ErrorText';
import Spinner from 'components/Spinner';
import SuccessText from 'components/SuccessText';
import CommandContext from 'context/Command';
import { keyCodes } from 'hooks/constants';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import loading from 'images/loading.gif';
import mushroom from 'images/Terminal/mushroom.png';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide';

const MovieProperties = {
    atmos: 'Atmos',
    hdr: 'HDR',
    'ddp5.1': '5.1',
    '.5.1': '5.1',
    '7.1': '7.1',
    bluray: 'BluRay',
    '2160p': '4K',
    '10bit': '10bit',
    _recommended: 'Recommended',
    _also_good: 'Also Good',
} as const;
type MoviePropertyKey = keyof typeof MovieProperties;
type MoviePropertyValue = (typeof MovieProperties)[MoviePropertyKey];

type Props = {
    movie: Awaited<ReturnType<typeof searchImdb>>[number];
};

const idealSet: MoviePropertyValue[] = ['5.1', 'Atmos', 'HDR', '4K'];
const alsoGood: MoviePropertyValue[] = ['7.1', 'Atmos', 'HDR', '4K'];

const getProperties = (title: string): MoviePropertyValue[] => {
    const keys = Array.from(Object.keys(MovieProperties)) as MoviePropertyKey[];
    title = title.toLowerCase();

    return keys.reduce((accum, curr) => {
        if (title.includes(curr)) {
            accum.push(MovieProperties[curr]);
        }

        return accum;
    }, [] as MoviePropertyValue[]);
};

const stripNameFromLinkTitle = (movie: Props['movie'], title: string) => {
    const nameParts = movie.name.split(' ');
    const titleParts = title.split('.');

    while (nameParts[0] === titleParts[0]) {
        nameParts.shift();
        titleParts.shift();
    }

    if (titleParts[0].toString() === movie.year.toString()) {
        titleParts.shift();
    }

    return titleParts.join('.');
};

const LineItem = ({
    selected,
    lineItem,
    onClick,
}: {
    selected: boolean;
    lineItem: Awaited<ReturnType<typeof searchRarbg>>[number] & {
        properties: MoviePropertyValue[];
    };
    onClick: (e: any) => void;
}) => {
    const { print, exit } = useContext(CommandContext);
    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    const { mutate: download } = useMutation(
        () => {
            return recordMagnetSelection(lineItem.link);
        },
        {
            onSuccess: () => {
                print?.(<SuccessText>Movie added!</SuccessText>);
                exit?.();
            },
            onError: () => {
                print?.(<ErrorText>Could not add movie</ErrorText>);
                exit?.();
            },
        }
    );

    useKeydown((e: any) => {
        const key = e.charCode || e.keyCode;
        if (key === keyCodes.ENTER) {
            download();
        }
    }, selected);

    useLayoutEffect(() => {
        if (selected) {
            ref?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selected, ref]);

    return (
        <LineItemContainer selected={selected} ref={setRef} onClick={onClick}>
            <PropertiesContainer>
                {lineItem.properties.map((p, pidx) => (
                    <PropertiesPill key={pidx} property={p}>
                        {p}
                    </PropertiesPill>
                ))}
            </PropertiesContainer>
            <PropertiesContainer>
                <PropertiesPill>{lineItem.seeders} seeders</PropertiesPill>
                <PropertiesPill>{lineItem.size.toFixed(2)}GB</PropertiesPill>
            </PropertiesContainer>
        </LineItemContainer>
    );
};

export default ({ movie }: Props) => {
    const { data, isLoading } = useQuery(['rarbg-search', movie.id], () =>
        searchRarbg(movie.id)
    );
    const [qualityIdx, setQualityIdx] = useState(0);

    const magnetLinks = useMemo(
        () =>
            (data ?? [])
                .map((l) => {
                    const properties = getProperties(
                        stripNameFromLinkTitle(movie, l.title)
                    );
                    const recommended =
                        idealSet.every((x) => properties.includes(x)) &&
                        l.size <= 20;
                    const runnerUp =
                        alsoGood.every((x) => properties.includes(x)) &&
                        l.size <= 20;

                    if (recommended) {
                        properties.unshift(MovieProperties._recommended);
                    }

                    if (runnerUp) {
                        properties.unshift(MovieProperties._also_good);
                    }

                    return {
                        ...l,
                        properties,
                    };
                })
                .sort((a, b) => {
                    const firstA = a.properties[0];
                    const firstB = b.properties[0];
                    if (
                        (firstA !== 'Recommended' &&
                            firstB !== 'Recommended' &&
                            firstA !== 'Also Good' &&
                            firstB !== 'Also Good') ||
                        firstA === firstB
                    ) {
                        return b.seeders - a.seeders;
                    }

                    if (firstA === 'Recommended' || firstB === 'Recommended') {
                        return firstA === 'Recommended' ? -1 : 1;
                    }

                    return firstA === 'Also Good' ? -1 : 1;
                }),
        [data, movie]
    );
    useKeydown((e: any) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.ENTER) {
        }

        if (key === keyCodes.UP || key === keyCodes.DOWN) {
            const direction = key === keyCodes.DOWN ? 1 : -1;
            const newIndex = Math.min(
                Math.max(0, qualityIdx + direction),
                magnetLinks.length - 1
            );

            setQualityIdx(newIndex);
        }
    }, true);

    const lineItemOnClick = (i: number) => (e: any) => {
        // @ts-expect-error: this isn't defined type yet
        if (!window.mobilecheck()) return;

        setQualityIdx(i);
    };

    return (
        <Container>
            <LeftColumn>
                <PosterImage src={movie.image} />
                <span>{movie.name}</span>
                <span>({movie.year})</span>
                {movie.starring && <span>{movie.starring}</span>}
            </LeftColumn>
            <Divider />
            <RightColumn>
                <div>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        magnetLinks.map((l, idx) => {
                            const selected = idx === qualityIdx;

                            return (
                                <LineItem
                                    key={idx}
                                    selected={selected}
                                    lineItem={l}
                                    onClick={lineItemOnClick(idx)}
                                />
                            );
                        })
                    )}
                </div>
            </RightColumn>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    height: 100%;
    font-size: 16px;
    color: ${colors.purpleBaliHai};
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 0 1 33%;

    align-items: center;
    justify-content: center;
    text-align: center;

    gap: 8px;
`;

const Divider = styled.div`
    width: 1px;
    height: 95%;
    background-color: ${colors.purpleBaliHai};

    margin-left: 8px;
    margin-right: 8px;

    align-self: center;
`;

const PosterImage = styled.div<{ src?: string }>`
    height: 196px;
    width: 130px;
    object-fit: cover;
    image-rendering: pixelated;
    background-color: ${colors.blueCharade};
    background-image: url(${(p) => (!p.src || p.src === '' ? mushroom : p.src)}),
        url(${loading});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1 1 66%;
    max-width: 66%;

    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    & > div {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;
        gap: 8px;
    }
`;

const LineItemContainer = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 4px;

    background-color: ${(p) => (p.selected ? colors.purpleBaliHai : 'unset')};
    color: ${(p) => (p.selected ? colors.blueCharade : 'inherit')};
    gap: 6px;
`;

const PropertiesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
`;

const PropertiesPill = styled.div<{ property?: MoviePropertyValue }>`
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    background-color: ${(p) =>
        p.property === 'Recommended'
            ? colors.greenExecutable
            : p.property === 'Also Good'
            ? colors.yellowPicasso
            : colors.green};
    color: ${colors.blueCharade};
    font-size: 12px;
    border-radius: 2px;

    flex: 0 1 auto;
    text-align: center;
    width: fit-content;
`;
