import { COOKIES } from 'constants';
import Cookies from 'js-cookie';
import { useLayoutEffect, useState } from 'react';

import AppContext from 'context/App';

import Page from 'components/Layout/Page';
import TerminalView from 'components/Terminal';
import { FileTypes } from 'files/File';
import root from 'files/root';
import useUser from 'hooks/User/useUser';
import { findFile } from 'programs/utils/file-utils';

import { v4 as uuid } from 'uuid';

const App = () => {
    const [pageHeight, setPageHeight] = useState(undefined);

    const [currentApp, setCurrentApp] = useState(null);
    const returnToTerminal = () => setCurrentApp(null);
    const { user, setUser } = useUser();

    const getDeviceToken = () => {
        const cookie = Cookies.get(COOKIES.RH_DEVICE_TOKEN);
        if (cookie && cookie !== '') {
            return cookie;
        }

        const token = uuid();
        Cookies.set(COOKIES.RH_DEVICE_TOKEN, token);
        return token;
    };

    const initialState = {
        currentApp,
        returnToTerminal,
        setCurrentApp,
        user,
        setUser,
        fsRoot: root,
        deviceToken: getDeviceToken(),
    };

    useLayoutEffect(() => {
        const path =
            window.location.hostname.split('.')[0] === 'photos'
                ? '/Users/moldy530/Photographer/gallery'
                : window.location.pathname;

        const File = findFile({
            path: [path],
            root,
            cwd: root,
        });

        if (!File || !(File.file_type === FileTypes.FILE)) return;

        setCurrentApp(<File.data returnToTerminal={returnToTerminal} />);
    }, []);

    // this handles the mobile case where the page doesn't resize when the keyboard disappears
    useLayoutEffect(() => {
        if (!window.mobilecheck()) return;

        const handler = () => setPageHeight(window.innerHeight);

        window.addEventListener('resize', handler, true);
        return () => window.removeEventListener('resize', handler, true);
    }, []);

    return (
        <AppContext.Provider value={initialState}>
            <Page pageHeight={pageHeight}>
                <TerminalView active={currentApp === null} />
                {currentApp}
            </Page>
        </AppContext.Provider>
    );
};

export default App;
