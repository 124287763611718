import Streams from 'components/Robinhood/Streams';
import StreamsGui from 'components/Robinhood/StreamsGui';

const rh_get_streams = {
    run: ({ print, exit, input, setCurrentApp }) => {
        const inputs = new Set(input.split(' '));

        if (inputs.has('--gui')) {
            setCurrentApp(<StreamsGui />);
            return exit();
        }

        return print(<Streams />);
    },
};

export default rh_get_streams;
