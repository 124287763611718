import React from 'react';
import getRobinhoodPortfolio from 'api/sweater/portfolio-rh';
import Error from 'components/ErrorText';

const rh_login = {
    run: ({ print, exit, deviceToken: device_token }) => {
        getRobinhoodPortfolio({ device_token })
            .then((portfolios) => {
                portfolios.forEach((p) =>
                    print(<div> equity: ${p.equity}</div>)
                );
                exit();
            })
            .catch((err) => {
                print(<Error>{err.message}</Error>);
            })
            .finally(() => exit());
    },
};

export default rh_login;
