import ImagePalette from 'components/ImagePalette';
import File, { FileTypes } from 'files/File';
import Developer from './index';

export default new File({
    fileType: FileTypes.FILE,
    name: 'image-palette.sample',
    executable: true,
    parent: () => Developer,
    data: ImagePalette,
});
