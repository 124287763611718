/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@tanstack/react-query';
import searchImdb from 'api/movies/search-imdb';
import { Container } from 'components/Movies';
import CommandContext from 'context/Command';
import { keyCodes } from 'hooks/constants';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import {
    TouchEventHandler,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import styled from 'styled-components';
import Poster from './Poster';

type Props = {
    searchTitle: string;
};

export default ({ searchTitle }: Props) => {
    const { running, exit, print } = useContext(CommandContext);
    const [movieSelected, setMovieSelected] = useState(false);
    const [index, setIndex] = useState(0);
    const { data, isLoading } = useQuery(
        ['imdb-search', searchTitle],
        () => searchImdb(searchTitle),
        {
            retryDelay: 2000,
            onError: () => {
                print?.('Oops something went wrong searching');
                exit?.();
            },
        }
    );

    const movies = useMemo(() => data ?? [], [data]);

    const cappedMoveIndex = useCallback(
        (direction: number) => {
            const newIndex = Math.min(
                Math.max(0, index + direction),
                movies.length - 1
            );

            setIndex(newIndex);
        },
        [index, movies]
    );

    useKeydown((e: KeyboardEvent) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.LEFT || key === keyCodes.RIGHT) {
            cappedMoveIndex(key === keyCodes.RIGHT ? 1 : -1);
        }
    }, running && !movieSelected);

    const translate = -Math.max(0, index - 1) * 146;

    // mobile touch handlers
    const [movePos, setMovePos] = useState(0);
    const slideStartHandler: TouchEventHandler<HTMLDivElement> = useCallback(
        (e) => setMovePos(e.touches[0].pageX),
        []
    );
    const slideMoveHandler: TouchEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            const touch = e.touches[0];
            const delta = movePos - touch.pageX;

            if (Math.abs(delta) > 50) {
                cappedMoveIndex(delta > 0 ? 1 : -1);
                setMovePos(touch.pageX);
            }
        },
        [cappedMoveIndex, movePos]
    );

    return (
        <Container
            // @ts-ignore
            enableGradient={index > 0}
            onTouchStart={slideStartHandler}
            onTouchMove={slideMoveHandler}
        >
            {isLoading ? (
                'Searching...'
            ) : (
                <Slider translateX={translate}>
                    {movies.map((m, idx) => (
                        <Poster
                            key={m.id}
                            movie={m}
                            selected={idx === index && !!running}
                            onSelection={(selected) =>
                                setMovieSelected(selected)
                            }
                        />
                    ))}
                </Slider>
            )}
        </Container>
    );
};

const Slider = styled.div<{ translateX: number }>`
    display: flex;
    flex-direction: row;

    transform: translateX(${(p) => p.translateX}px);
    transition: transform 200ms ease-in-out 0ms;
`;
