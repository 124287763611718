const echo = {
    man: {
        description: 'display a line of text',
        usage: ['echo <text to display>'],
    },
    run: ({ input, print, exit }) => {
        const toEcho = input.split(' ').slice(1).join(' ');

        if (toEcho.toLowerCase().indexOf('fuck jo') > -1) {
            print('yes please');
            return exit();
        }

        print(toEcho);
        exit();
    },
};

export default echo;
