import TreeTable from 'components/TreeTable';
import File, { FileTypes } from 'files/File';
import Developer from './index';

export default new File({
    fileType: FileTypes.FILE,
    executable: true,
    name: 'tree-table(WIP).sample',
    parent: () => Developer,
    data: TreeTable,
});
