import * as d3 from 'd3';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { colors, mediaQueries } from 'styleguide';

import AppContext from 'context/App';

import useInterrupt from 'hooks/KeyEvent/useInterrupt';
import useStreamsPolling from 'hooks/Robinhood/useStreamsPolling';
import useD3 from 'hooks/d3/useD3';

const SweaterGraphCard = ({ user, portfolio }) => {
    const [
        {
            general,
            historicals: { equity_historicals, previous_close_equity },
        },
    ] = portfolio;

    const returnPercentage =
        Math.round(general.percentage_return * 100 * 100) / 100;

    const ref = useD3((svg) => {
        if (!svg) return;

        const { height, width } = ref.current.getBoundingClientRect();
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };

        equity_historicals.forEach((eq) => {
            eq.begins_at = Date.parse(eq.begins_at);
            eq.close_equity = Number(eq.close_equity);
        });

        const x = d3
            .scaleTime()
            .domain(d3.extent(equity_historicals, (d) => d.begins_at))
            .rangeRound([margin.left, width - margin.right]);

        const y = d3
            .scaleLinear()
            .domain(
                d3.extent([
                    ...equity_historicals.map((d) => d.close_equity),
                    Number(previous_close_equity),
                ])
            )
            .rangeRound([height - margin.bottom, margin.top]);

        const valueline = d3
            .line()
            .x((d) => x(d.begins_at))
            .y((d) => y(d.close_equity))
            .curve(d3.curveMonotoneX);

        const plotArea = svg.select('g.plot-area');
        plotArea.select('path').remove();

        plotArea
            .append('path')
            .datum(equity_historicals)
            .attr('class', 'line')
            .attr(
                'stroke',
                returnPercentage > 0
                    ? colors.green
                    : returnPercentage < 0
                    ? colors.redFroly
                    : colors.frenchGray
            )
            .attr('fill', 'none')
            .attr('stroke-width', 1)
            .attr('d', valueline);

        const baseLineArea = svg.select('g.base-line-area');
        baseLineArea.select('line').remove();

        baseLineArea
            .append('line')
            .attr('height', height)
            .attr('width', width)
            .attr('y1', y(Number(previous_close_equity)))
            .attr('y2', y(Number(previous_close_equity)))
            .attr('x1', x(equity_historicals[0].begins_at))
            .attr(
                'x2',
                x(equity_historicals[equity_historicals.length - 1].begins_at)
            )
            .attr('class', 'base-line')
            .attr('stroke', colors.blueCharade)
            .attr('fill', 'none')
            .attr('stroke-width', 1)
            .style('stroke-dasharray', '2, 5');
    });

    return (
        <SweaterContainer>
            <SweaterHeader>
                <UserName>{user}</UserName>
                <TotalReturn returnPercentage={returnPercentage}>
                    {returnPercentage}%
                </TotalReturn>
            </SweaterHeader>
            <SweaterGraphContainer>
                <svg
                    ref={ref}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <g className="base-line-area" />
                    <g className="plot-area" />
                </svg>
            </SweaterGraphContainer>
        </SweaterContainer>
    );
};

const Sweaters = ({ sweaters }) => {
    return sweaters.map(([user, portfolio]) => (
        <SweaterGraphCard key={user} user={user} portfolio={portfolio} />
    ));
};

export default () => {
    const { returnToTerminal, deviceToken: device_token } =
        useContext(AppContext);

    const [running, setRunning] = useState(true);
    const sweaters = useStreamsPolling({
        onError: () => null,
        device_token,
        running,
    });

    useInterrupt(() => {
        setRunning(false);
        returnToTerminal();
    });

    return (
        <Container>
            <h1 style={{ marginLeft: 12 }}>
                Welcome to
                <br />
                Sweater.gg
            </h1>
            <SweaterGrid>
                {sweaters && sweaters.length ? (
                    <Sweaters sweaters={sweaters} />
                ) : (
                    <div>No one is sweating right now</div>
                )}
            </SweaterGrid>
        </Container>
    );
};

const Container = styled.div`
    position: relative;

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    height: 100vh;

    background-color: #fafafa;

    font-family: 'Roboto Mono', monospace;

    padding: 32px 24px;
    padding-right: 0px;

    ${mediaQueries.mobile`
        flex-direction: column;
    `};
`;

const SweaterGrid = styled.div`
    display: grid;
    flex: 1 1 auto;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100%;
    grid-gap: 16px;
    grid-auto-flow: dense;

    align-items: center;
    justify-items: center;

    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    padding-right: 24px;

    ${mediaQueries.tabletAndMobile`
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(100%, 1fr);
    `};
`;

const SweaterContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 24px;

    height: 375px;
    width: 375px;

    border-radius: 20px;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;

    box-shadow: -4px -2px 4px 0px #ffffff, 4px 2px 6px 0px #dfe4ea;
`;

const SweaterHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

const UserName = styled.div`
    font-size: 24px;
    padding-right: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TotalReturn = styled.div`
    margin-left: auto;
    font-size: 16px;

    color: white;
    border-radius: 8px;
    background: ${(p) =>
        p.returnPercentage > 0
            ? colors.green
            : p.returnPercentage < 0
            ? colors.redFroly
            : undefined};

    color: white;
    height: 24px;
    width: 69px;
    justify-content: center;
    align-items: center;
    display: flex;

    padding: 12px;
`;

const SweaterGraphContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin-top: 24px;
`;
