import apiCall from 'api/api-call';

type Response = {
    title: string;
    link: string;
    size: number;
    seeders: number;
}[];

export default (imdbId: string) =>
    apiCall<undefined, Response>({
        path: `/v1/search/rarbg/${imdbId}`,
        method: 'GET',
    });
