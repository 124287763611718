export default ({ email, password }) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/user/sign-in`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email,
            password,
        }),
    }).then((response) => {
        if (response.ok) return response.json();
        throw new Error('login for user failed');
    });
};
