import { useEffect, useState, useRef, useCallback } from 'react';
import getStreams from 'api/sweater/get-streams';

export default ({ onError, device_token, running }) => {
    const [sweaters, setSweaters] = useState(null);
    const intervalRef = useRef(null);
    const pollStream = useCallback(() => {
        getStreams({ device_token })
            .then((streams) => {
                setSweaters(Object.entries(streams));
                intervalRef.current = setTimeout(pollStream, 5000);
            })
            .catch(onError);
    }, [device_token, onError]);

    useEffect(() => {
        if (!running) {
            clearTimeout(intervalRef.current);
            return;
        }

        if (!intervalRef.current) {
            pollStream();
        }
    }, [pollStream, running]);

    return sweaters;
};
