import ManPage from 'components/ManPage';

import adduser from './adduser';
import advancedMovieSearch from './advanced-movie-search';
import cd from './cd';
import clear from './clear';
import echo from './echo';
import login from './login';
import ls from './ls';
import open from './open';
import searchMovies from './search-movies';
import rhGetStreams from './sweater/rh-get-streams';
import rhLogin from './sweater/rh-login';
import rhPortfolio from './sweater/rh-portfolio';
import rhStartStream from './sweater/rh-start-stream';
import rhStopStream from './sweater/rh-stop-stream';
import trustUser from './trust-user';
import whoami from './whoami';

// this has to live in here because it won't compile (probs circular dependency)
const help = {
    man: {
        description:
            'lists all of the available commands and information about how to use them',
    },
    run: ({ print, exit }) => {
        print("Here's a list of available commands");
        Object.keys(programs).forEach((key) => {
            if (!programs[key].man) {
                return;
            }

            const { description, usage } = programs[key].man;

            const manPage = new ManPage({
                name: key,
                description,
                usage,
            });

            print(manPage);
        });

        exit();
    },
};

const programs = {
    adduser,
    cd,
    clear,
    echo,
    help,
    login,
    ls,
    open,
    search: searchMovies,
    'search-movies': searchMovies,
    'trust-user': trustUser,
    whoami,
    'rh-login': rhLogin,
    'rh-portfolio': rhPortfolio,
    'rh-get-streams': rhGetStreams,
    'rh-start-stream': rhStartStream,
    'rh-stop-stream': rhStopStream,
    'advanced-search': advancedMovieSearch,
};

const commandRuns = Object.keys(programs).reduce((result, key) => {
    result[key] = programs[key].run;
    return result;
}, {});

export default {
    ...commandRuns,
};
