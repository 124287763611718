import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'styleguide';
import loading from 'images/loading.gif';

import MoviesContext from 'context/Movies';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import { keyCodes } from 'hooks/constants';

const Poster = ({ movie, selected, onClick }) => {
    const [flip, setFlip] = useState(false);
    const [qualityIdx, setQualityIdx] = useState(-1);
    const { recordSelection } = useContext(MoviesContext);

    useKeydown((e) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.ENTER) {
            if (!flip) setFlip(true);
            if (flip && qualityIdx >= 0) {
                setQualityIdx(-1);
                recordSelection({
                    id: movie.id,
                    ...movie.quality[qualityIdx],
                });
            }
        }

        if (key === keyCodes.UP || key === keyCodes.DOWN) {
            const direction = key === keyCodes.DOWN ? 1 : -1;
            const newIndex = Math.min(
                Math.max(0, qualityIdx + direction),
                movie.quality.length - 1
            );

            setQualityIdx(newIndex);
        }
    }, selected);

    // prettier-ignore
    useEffect(() => {
        if (!selected) {
            setFlip(false);
            setQualityIdx(-1);
        }
    }, [selected]);

    const posterOnClick = (e) => {
        // we only do this on mobile
        if (!window.mobilecheck()) return;

        onClick(e);
        setFlip(selected && !flip);
    };

    const qualityOnClick = (i) => (e) => {
        if (!window.mobilecheck()) return;
        e.stopPropagation();

        if (!flip) return;

        if (qualityIdx === i) {
            setQualityIdx(-1);
            recordSelection({
                id: movie.id,
                ...movie.quality[qualityIdx],
            });
        } else {
            setQualityIdx(i);
        }
    };

    return (
        <PosterContainer selected={selected} onClick={posterOnClick}>
            <StyledImage flip={flip && selected} src={movie.poster} />
            <Behind>
                {movie.quality.map((q, i) => (
                    <QualityContainer
                        key={i}
                        selected={qualityIdx === i}
                        onClick={qualityOnClick(i)}
                    >
                        {q.quality} ({q.type})
                    </QualityContainer>
                ))}
            </Behind>
        </PosterContainer>
    );
};

const PosterContainer = styled.div`
    padding: 8px;
    ${(p) => (p.selected ? SelectedCss : '')};
    position: relative;
    z-index: 0;
`;

const SelectedCss = css`
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${colors.blueMalibu};
        opacity: 0.4;
        content: '';
        z-index: -1;
    }
`;

const StyledImage = styled.div`
    height: 196px;
    min-width: 130px;
    background-color: ${colors.blueCharade};
    background-image: url(${(p) => p.src}), url(${loading});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    ${(p) => (p.flip ? 'transform: rotateY(-90deg)' : null)};
    transition: transform 0.25s ease-in-out 0s;
`;

const Behind = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${colors.blueCharade};
    z-index: -1;
    margin: 8px;
`;

const QualityContainer = styled.div`
    position: relative;
    font-size: 14px;
    text-align: center;

    width: 100%;

    margin: 0px 8px;
    padding: 8px 0;

    ${(p) => (p.selected ? SelectedCss : '')};
`;

export default Poster;
