async function apiCall<Body, Res>({
    path,
    method,
    processResponse = true,
    body = undefined,
}: {
    path: string;
    method: 'GET' | 'POST' | 'DELETE' | 'PUT';
    body?: Body;
    processResponse?: boolean;
}): Promise<Res> {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';
    return fetch(`${host}${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: body && JSON.stringify(body),
    }).then((res: Response) => {
        if (res.ok) {
            return processResponse ? res.json() : res.text();
        }

        throw res.text();
    });
}

export default apiCall;
