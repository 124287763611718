import Cookies from 'js-cookie';
import { COOKIES } from 'constants';

export default ({
    email,
    password,
    challenge_type,
    challenge_id,
    mfa,
    device_token,
}) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/sweater/authenticate`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password,
            challenge_type,
            challenge_id,
            mfa,
            device_token,
        }),
    }).then(async (response) => {
        const body = await response.json();

        if (response.ok && body.token) {
            Cookies.set(COOKIES.RH_ACCESS_TOKEN, body.token);
            return body;
        }

        if (response.status === 400) {
            if (body.accept_challenge_types) return body;
            if (body.challenge) return body;
        }

        if (response.status === 429) {
            const body = await response.json();
            throw new Error(body.detail);
        }

        throw new Error('failed to authenticate with RH');
    });
};
