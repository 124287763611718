import File, { FileTypes } from 'files/File';
import moldy530 from '..';
import Resume from './Resume';
import ImageColors from './image-palette';
import TreeTable from './tree-table';

export default new File({
    fileType: FileTypes.DIRECTORY,
    name: 'Developer',
    parent: () => moldy530,
    children: () => [Resume, TreeTable, ImageColors],
});
