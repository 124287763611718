import RobinhoodLogin from 'components/Robinhood/Login';
import parseUser from 'programs/utils/parse-user';

const rh_login = {
    run: ({ print, input, exit }) => {
        const user = parseUser({
            input,
            command: 'rh-login',
            print,
            exit,
        });

        if (!user) return exit();

        return print(<RobinhoodLogin user={user} />);
    },
};

export default rh_login;
