import React from 'react';
import styled from 'styled-components';

import useInputWithCursor from 'hooks/InputWithCursor/useInputWithCursor';
import TextWithCursor from 'components/TextWithCursor';

const SettingsPrompt = ({ setMeans, isActive }) => {
    const { inputs, cursorPosition, updateInputs } = useInputWithCursor({
        isActive,
        handleSubmit: () => {
            updateInputs([]);

            const command = inputs.join('').split(' ');
            if (command[0] === 'means' && command[1]) {
                const number = parseInt(command[1], 10);
                if (number) setMeans(number);
            }
        },
    });

    return (
        <Container>
            <span>&gt;</span>
            <TextWithCursor
                cursorPosition={cursorPosition}
                isActive={isActive}
                text={inputs.join('')}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;

    & > * {
        margin-right: 4px;
    }
`;

export default SettingsPrompt;
