import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'styleguide';

import AppContext from 'context/App';
import CommandContext from 'context/Command';
import MoviesContext from 'context/Movies';

import useKeydown from 'hooks/KeyEvent/useKeydown';
import { keyCodes } from 'hooks/constants';

import CommandOutputContainer from 'components/CommandOutputContainer';
import Error from 'components/ErrorText';
import Poster from 'components/Movies/Poster';
import Success from 'components/SuccessText';

import record from 'api/movies/record-selection';

const Movies = ({ movies }) => {
    const [index, setIndex] = useState(0);
    const { running, print, exit } = useContext(CommandContext);
    const { user } = useContext(AppContext);

    const cappedMoveIndex = useCallback(
        (direction) => {
            const newIndex = Math.min(
                Math.max(0, index + direction),
                movies.length - 1
            );

            setIndex(newIndex);
        },
        [index, movies]
    );

    useKeydown((e) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.LEFT || key === keyCodes.RIGHT) {
            cappedMoveIndex(key === keyCodes.RIGHT ? 1 : -1);
        }
    }, running);

    const recordSelection = useMemo(
        () =>
            ({ id, quality, type }) => {
                if (!user) {
                    print(<Error>you must login to record selections</Error>);
                    return exit();
                }

                setIndex(-1);
                print('recording selection...');
                record({
                    id,
                    quality,
                    type,
                    user,
                })
                    .then(() => {
                        print(<Success>selection recorded!</Success>);
                        exit();
                    })
                    .catch((e) => {
                        print(<Error>{e.message}</Error>);
                        exit();
                    });
            },
        [user, print, exit]
    );

    const translate = -Math.max(0, index - 1) * 146;

    // mobile touch handlers
    const [movePos, setMovePos] = useState(0);
    const slideStartHandler = useCallback(
        (e) => setMovePos(e.touches[0].pageX),
        []
    );
    const slideMoveHandler = useCallback(
        (e) => {
            const touch = e.touches[0];
            const delta = movePos - touch.pageX;

            if (Math.abs(delta) > 50) {
                cappedMoveIndex(delta > 0 ? 1 : -1);
                setMovePos(touch.pageX);
            }
        },
        [cappedMoveIndex, movePos]
    );

    return (
        <MoviesContext.Provider value={{ recordSelection }}>
            <Container
                enableGradient={index > 0}
                onTouchStart={slideStartHandler}
                onTouchMove={slideMoveHandler}
            >
                <Slider translate={translate}>
                    {movies.map((m, i) => (
                        <Poster
                            key={m.id}
                            movie={m}
                            onClick={() => setIndex(i)}
                            selected={i === index && running}
                        />
                    ))}
                </Slider>
            </Container>
        </MoviesContext.Provider>
    );
};

export const Slider = styled.div`
    display: flex;
    flex-direction: row;

    transform: translateX(${(p) => p.translate}px);
    transition: transform 200ms ease-in-out 0ms;
`;

export const Container = styled(CommandOutputContainer)`
    z-index: 0;

    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-image: linear-gradient(
            to right,
            ${colors.blueCharade},
            rgba(0, 0, 0, 0)
        );
        opacity: ${(p) => (p.enableGradient ? 1 : 0)};
        transition: opacity 0.4s ease-in-out 0s;
        content: '';
        width: 150px;
        pointer-events: none;
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            ${colors.blueCharade}
        );
        content: '';
        width: 150px;
        pointer-events: none;
    }
`;

export default Movies;
