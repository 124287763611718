import { findFile } from './utils/file-utils';
import { FileTypes } from 'files/File';

const cd = {
    man: {
        description: 'changes the current working directory',
        usage: [
            'cd <dir> - switch to specified directory',
            'cd .. - moves up one level',
            'cd ../<dir> - moves to directory after moving up one level',
        ],
    },
    run: ({ cwd, setCwd, input, print, fsRoot, exit }) => {
        const dir = input.split(' ').slice(1);
        if (dir.length > 1) {
            print(`unrecognized input: ${[...dir.slice(1)]}`);
            return exit();
        }

        if (dir.length === 0 || dir[0] === '') {
            return exit();
        }

        const newDir = findFile({
            cwd,
            path: dir,
            root: fsRoot,
        });

        if (!newDir || newDir.file_type !== FileTypes.DIRECTORY) {
            print(`${dir} is not a directory`);
            return exit();
        }

        setCwd(newDir);
        exit();
    },
};

export default cd;
