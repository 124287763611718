import { useCallback, useReducer } from 'react';

import CommandOutputContainer from 'components/CommandOutputContainer';
import Prompt from 'components/Terminal/Prompt';
import { actions } from './constants';

const stdOutReducer = (state, action) => {
    switch (action.type) {
        case actions.PRINT:
            return [...state, action.payload];
        case actions.CLEAR:
            // never figured it out but this needed a unique key :shrug:
            return [<Prompt key={new Date().getMilliseconds()} />];
        default:
            return state;
    }
};

export default () => {
    const [stdOut, dispatch] = useReducer(stdOutReducer, []);

    const print = useCallback((obj) => {
        obj = (() => {
            if (typeof obj === 'string')
                return <CommandOutputContainer>{obj}</CommandOutputContainer>;

            return obj;
        })();

        dispatch({
            type: actions.PRINT,
            payload: obj,
        });
    }, []);

    const clear = useCallback(() => dispatch({ type: actions.CLEAR }), []);

    return {
        stdOut,
        print,
        clear,
    };
};
