export default () => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/sweater/streams`, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((res) => {
        if (!res.ok) throw new Error('failed to get streams!');

        return res.json();
    });
};
