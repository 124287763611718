import apiCall from 'api/api-call';

type Response = {
    image?: string;
    id: string;
    type: string;
    name: string;
    year: string;
    starring?: string;
}[];

export default (title: string) =>
    apiCall<undefined, Response>({
        path: `/v1/search/imdb?title=${title}`,
        method: 'GET',
    });
