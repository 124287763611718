import { ReactElement, createContext } from 'react';

type CommandContextType = {
    running?: boolean;
    print?: (obj: string | ReactElement) => void;
    exit?: () => void;
};

const CommandContext = createContext<CommandContextType>({});

export default CommandContext;
