import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide';

import AppContext from 'context/App';
import CommandContext from 'context/Command';

import CommandOutputContainer from 'components/CommandOutputContainer';
import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

import useStreamsPolling from 'hooks/Robinhood/useStreamsPolling';

const Sweaters = ({ sweaters }) => {
    return sweaters.map(([user, portfolio]) => {
        const [{ general }] = portfolio;
        const returnPercentage = general.percentage_return * 100;

        return (
            <SweaterContainer>
                <UserName>{user}:</UserName>
                <TotalReturn returnPercentage={returnPercentage}>
                    {returnPercentage}%
                </TotalReturn>
            </SweaterContainer>
        );
    });
};

export default () => {
    const { deviceToken: device_token } = useContext(AppContext);
    const { exit, print, running } = useContext(CommandContext);
    const sweaters = useStreamsPolling({
        onError: (err) => {
            print(<Error>{err.message}</Error>);
            exit();
        },
        device_token,
        running,
    });

    useEffect(() => {
        if (!running) return;

        if (sweaters && sweaters.length === 0) {
            print(<Error>No one is streaming</Error>);
            exit();
        }
    }, [exit, print, running, sweaters]);

    return (
        <>
            {!sweaters && <Success>fetching...</Success>}
            <CommandOutputContainer>
                {sweaters && <Sweaters sweaters={sweaters} />}
            </CommandOutputContainer>
        </>
    );
};

const SweaterContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const UserName = styled.div`
    margin-right: 4px;
`;

const TotalReturn = styled.div`
    color: ${(p) =>
        p.returnPercentage > 0
            ? colors.green
            : p.returnPercentage < 0
            ? colors.redFroly
            : undefined};
`;
