import React from 'react';
import stopStream from 'api/sweater/stop-stream';
import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

const rh_stop_stream = {
    run: ({ print, exit, deviceToken: device_token }) => {
        stopStream({ device_token })
            .then(() => {
                print(<Success>successfully stopped streaming!</Success>);
            })
            .catch((err) => {
                print(<Error>{err.message}</Error>);
            })
            .finally(() => exit());
    },
};

export default rh_stop_stream;
