import searchImdb from 'api/movies/search-imdb';
import Modal from 'components/Modal';
import CommandContext from 'context/Command';
import { keyCodes } from 'hooks/constants';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import loading from 'images/loading.gif';
import mushroom from 'images/Terminal/mushroom.png';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styleguide';
import RarbgSearchResults from './RarbgSearchResults';

type Props = {
    movie: Awaited<ReturnType<typeof searchImdb>>[0];
    selected: boolean;
    onSelection: (selected: boolean) => void;
};

export default ({ movie, selected, onSelection }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const { running } = useContext(CommandContext);

    useEffect(() => {
        if (!running) {
            setShowModal(false);
            onSelection(false);
        }
    }, [onSelection, running]);

    useKeydown((e: any) => {
        const key = e.charCode || e.keyCode;

        if (key === keyCodes.ENTER) {
            setShowModal(true);
            onSelection(true);
        }
    }, selected && !showModal);

    const posterOnClick = (e: any) => {
        // we only do this on mobile
        // @ts-expect-error: we haven't defined this yet
        if (!window.mobilecheck()) return;

        setShowModal(true);
        onSelection(true);
    };

    return (
        <>
            <PosterContainer selected={selected} onClick={posterOnClick}>
                <Poster src={movie.image} />
            </PosterContainer>
            <Modal
                showModal={showModal}
                onClose={() => {
                    setShowModal(false);
                    onSelection(false);
                }}
            >
                <RarbgSearchResults movie={movie} />
            </Modal>
        </>
    );
};

const SelectedCss = css`
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${colors.blueMalibu};
        opacity: 0.4;
        content: '';
        z-index: -1;
    }
`;

const PosterContainer = styled.div<{ selected: boolean }>`
    padding: 8px;
    ${(p) => (p.selected ? SelectedCss : '')};
    position: relative;
    z-index: 0;
`;

const Poster = styled.img`
    height: 196px;
    width: 130px;
    min-width: 130px;
    object-fit: cover;
    image-rendering: pixelated;
    background-color: ${colors.blueCharade};
    background-image: url(${(p) => (!p.src || p.src === '' ? mushroom : p.src)}),
        url(${loading});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    transition: transform 0.25s ease-in-out 0s;
`;
