import apiCall from 'api/api-call';

export default (magnetLink: string) =>
    apiCall({
        path: '/v1/movies/magnet',
        method: 'POST',
        body: {
            link: magnetLink,
        },
        processResponse: false,
    });
