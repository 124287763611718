import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide';

const DragAndDropZone = ({ setFile }) => {
    const fileUploaderRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();

        e.dataTransfer.dropEffect = 'copy';
        e.dataTransfer.effectAllowed = 'copy';
    };

    const handleDrop = (drop) => {
        drop.preventDefault();
        drop.stopPropagation();

        const {
            dataTransfer: { files },
        } = drop;

        setFile(files[0]);
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFile(e.target.files[0]);
    };

    return (
        <DragAndDropContainer
            onClick={() => fileUploaderRef.current.click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <StyledInput
                ref={fileUploaderRef}
                onChange={handleFileChange}
                type="file"
            />
            <DragAndDropIcon>
                Drag a file here <br />
                (or click to browse)
            </DragAndDropIcon>
        </DragAndDropContainer>
    );
};

const DragAndDropContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
`;

const DragAndDropIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 100%;
    height: 100%;

    border: 2px dashed ${colors.frenchGray};
    border-radius: 5px;

    cursor: copy;
`;

const StyledInput = styled.input`
    display: none;
`;

export default DragAndDropZone;
