import { useContext, useState } from 'react';
import styled from 'styled-components';

import CommandContext from 'context/Command/index';
import useInputWithCursor from 'hooks/InputWithCursor/useInputWithCursor';

import MobileInput from 'components/MobileInput';

const Password = ({ setPassword, passwordText = 'password' }) => {
    const { running } = useContext(CommandContext);

    const [active, setActive] = useState(true);
    const { inputs, mobileInputHandler } = useInputWithCursor({
        isActive: active && running,
        handleSubmit: () => {
            setActive(false);
            setPassword(inputs.join(''));
        },
    });

    return (
        <Container>
            {passwordText}:{' '}
            {window.mobilecheck() && (
                <MobileInput
                    type="password"
                    inputs={inputs}
                    isActive={active && running}
                    onChange={mobileInputHandler}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    padding-bottom: 8px;
`;

export default Password;
