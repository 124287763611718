export default ({ email, token }) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/user/trust-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            userEmail: email,
        }),
    }).then((response) => {
        if (response.ok) return 'ok';
        throw new Error('login for user failed');
    });
};
