import React from 'react';
import startStream from 'api/sweater/start-stream';
import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

const rh_start_stream = {
    run: ({ print, exit, deviceToken: device_token }) => {
        startStream({ device_token })
            .then(() => {
                print(<Success>successfully started stream!</Success>);
            })
            .catch((err) => {
                print(<Error>{err.message}</Error>);
            })
            .finally(() => exit());
    },
};

export default rh_start_stream;
