import React from 'react';

import { findFile } from './utils/file-utils';
import { FileTypes } from '../files/File';

const open = {
    man: {
        description: 'opens a file given a path to it',
        usage: ['open <path-to-file>'],
    },
    run: ({
        cwd,
        fsRoot,
        input,
        print,
        setCurrentApp,
        returnToTerminal,
        exit,
    }) => {
        const dir = input.split(' ').slice(1);
        if (dir.length > 1) {
            print(`unrecognized input: ${[...dir.slice(1)]}`);
            return exit();
        }

        if (dir.length === 0 || dir[0] === '') {
            return exit();
        }

        const File = findFile({
            cwd,
            root: fsRoot,
            path: dir,
        });

        if (!File || !(File.file_type === FileTypes.FILE)) {
            print(`${dir} is not a file`);
            return exit();
        }

        // TODO: ideally, we use file extensions to use the right application
        setCurrentApp(<File.data returnToTerminal={returnToTerminal} />);
        exit();
    },
};

export default open;
