/*
 * file_type: directory || file
 * data
 * name: name of the file / directory
 * parent: parent directory
 * children: []
 */
export const FileTypes = {
    DIRECTORY: 'DIRECTORY',
    FILE: 'FILE',
};

function File({
    name,
    parent,
    fileType,
    executable = false,
    children = () => [],
    data = undefined,
}) {
    if (!FileTypes.hasOwnProperty(fileType)) {
        throw new Error(`${fileType} is not a supported file type`);
    }

    this.file_type = fileType; // TODO: add enum for this?
    this.data = data;
    this.name = name;
    this.parent = parent;
    this.executable = executable;
    this.children = fileType === FileTypes.FILE ? undefined : children;
}

export default File;
