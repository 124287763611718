import { COOKIES } from 'constants';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import { useCallback, useState } from 'react';

const cookies = Cookies.withConverter({ read: (value) => JSON.parse(value) });

const useUser = () => {
    const [user, setUserInternal] = useState(cookies.get(COOKIES.USER));

    const setUser = useCallback(({ email, token }) => {
        const { exp } = jwt(token);

        Cookies.set(
            COOKIES.USER,
            {
                email,
                token,
            },
            { expires: new Date(exp * 1000) }
        );

        setUserInternal({
            email,
            token,
        });
    }, []);

    return {
        user,
        setUser,
    };
};

export default useUser;
