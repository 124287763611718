import loading from 'images/loading.gif';
import styled from 'styled-components';

export default styled.div<{ height?: number; width?: number }>`
    background-image: url(${loading});
    height: ${(p) => p.height ?? 64}px;
    width: ${(p) => p.width ?? 64}px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    align-self: center;
`;
