import directoryList from 'components/DirectoryList';

const ls = {
    man: {
        description: 'list the contents of the current working directory',
        usage: ['ls - shows the contents of the current directory'],
    },
    run: ({ cwd, print, exit }) => {
        // TODO: allow listing the contents of other directories
        print(directoryList({ cwdChildren: cwd.children() }));
        return exit();
    },
};

export default ls;
