import { useEffect, useRef } from 'react';

const instances = new Map();
const handleKeydown = (e) =>
    instances.forEach((i) => i.current.handleKeydown(e));

const registerInstance = (instance) => {
    if (instances.size === 0) {
        window.addEventListener('keydown', handleKeydown, true);
    }

    instances.set(instance.current.nodeId, instance);
};

const unregisterInstance = (instance) => {
    instances.delete(instance.current.nodeId);

    if (instances.size === 0) {
        window.removeEventListener('keydown', handleKeydown, true);
    }
};

export default (handler, shouldRegister = true) => {
    const instanceRef = useRef(null);

    useEffect(() => {
        if (!instanceRef.current) {
            instanceRef.current = {
                nodeId: new Date(),
                handleKeydown: handler,
            };
        }

        instanceRef.current.handleKeydown = handler;
    }, [handler]);

    useEffect(() => {
        if (!shouldRegister) return;

        registerInstance(instanceRef);

        return () => unregisterInstance(instanceRef);
    }, [shouldRegister]);
};
