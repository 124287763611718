import PhotoGallery from 'components/PhotoGallery';
import File, { FileTypes } from 'files/File';
import Photographer from './index';

export default new File({
    fileType: FileTypes.FILE,
    executable: true,
    name: 'gallery',
    parent: () => Photographer,
    data: PhotoGallery,
});
