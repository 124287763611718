import doSearch from 'api/movies/search-movies';
import Error from 'components/ErrorText';
import Movies from 'components/Movies';

const searchMovies = {
    run: ({ input, print, exit }) => {
        const searchTerms = input.split(' ').slice(1).join(' ');

        doSearch({ searchTerms })
            .then((movies) => {
                if (movies.length === 0) {
                    print('no results found.');
                    return exit();
                }

                const props = {
                    movies,
                };

                print(<Movies {...props} />);
            })
            .catch((e) => {
                print(<Error>{e.message}</Error>);
                exit();
            });
    },
};

export default searchMovies;
