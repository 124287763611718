import styled from 'styled-components';
import { colors, sizes } from 'styleguide';

const TextWithCursor = ({ isActive, text, cursorPosition }) => (
    <Container>
        <Text>{text}</Text>
        {isActive && <Cursor cursorPosition={cursorPosition} />}
    </Container>
);

const Container = styled.div`
    display: flex;
    flex: 1;

    position: relative;
    margin-left: 8px;
`;

const Text = styled.div`
    color: white;
    white-space: pre;
`;

const Cursor = styled.div`
    background-color: ${colors.purpleBaliHai};

    position: absolute;
    left: ${(p) => p.cursorPosition * sizes.promptTextEm}em;

    height: 16px;
    width: ${sizes.promptTextEm}em;

    @keyframes blink {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.8;
        }
    }
    animation: blink 1s infinite;
`;

export default TextWithCursor;
