import AdvancedSearch from 'components/AdvancedSearch';
import ErrorText from 'components/ErrorText';
import type { RunnableProgram } from './types';

export default {
    run: async ({ input, print, exit }) => {
        if (!input || input.split(' ').length === 1) {
            print(
                <ErrorText>
                    Usage: advanced-search &lt;movie-title&gt;
                </ErrorText>
            );
            return exit();
        }

        const searchTitle = input.split(' ').slice(1).join(' ');
        print(<AdvancedSearch searchTitle={searchTitle} />);
    },
} as RunnableProgram;
