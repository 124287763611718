/* eslint-disable jsx-a11y/accessible-emoji */
import styled from 'styled-components';

import { colors } from 'styleguide';
import FileViewer from '../FileViewer';

const Resume = ({ returnToTerminal }) => {
    const isMobile = window.mobilecheck();

    return (
        <FileViewer fileName="Resume" returnToTerminal={returnToTerminal}>
            <ResumeHeader>
                <Name>
                    <Mushroom aria-label="mushroom" role="img">
                        🍄
                    </Mushroom>
                    &nbsp;Radu Michael Moldoveanu&nbsp;
                    <Mushroom aria-label="mushroom" role="img">
                        🍄
                    </Mushroom>
                </Name>
                <ResumeSubheader>
                    phone: (706) 206-9012 email: moldy530@gmail.com
                </ResumeSubheader>
                <ResumeSubheader>website: https://www.moldy.sh</ResumeSubheader>
                <ResumeSubheader>
                    linkedin:
                    https://www.linkedin.com/pub/radu-moldoveanu/68/726/844
                </ResumeSubheader>
            </ResumeHeader>
            <ResumeSection>
                <ResumeSectionHeader>Education</ResumeSectionHeader>
                <ResumeSectionSubsection isMobile={isMobile}>
                    <ResumeSectionSubsectionHeader>
                        Columbia University
                    </ResumeSectionSubsectionHeader>
                    <TitleAndDates>
                        Master of Science in Computer Science, December 2015
                    </TitleAndDates>
                    <AdditionalInfo>GPA: 3.57 / 4.0</AdditionalInfo>
                </ResumeSectionSubsection>
                <ResumeSectionSubsection isMobile={isMobile}>
                    <ResumeSectionSubsectionHeader>
                        The University of Georgia
                    </ResumeSectionSubsectionHeader>
                    <TitleAndDates>
                        Bachelor of Science in Computer Science, May 2013
                    </TitleAndDates>
                    <AdditionalInfo>GPA: 3.62 / 4.0</AdditionalInfo>
                </ResumeSectionSubsection>
            </ResumeSection>
            <ResumeSection>
                <ResumeSectionHeader>Work Experience</ResumeSectionHeader>
                <ResumeSectionSubsection isMobile={isMobile}>
                    <ResumeSectionSubsectionHeader>
                        Zocdoc
                    </ResumeSectionSubsectionHeader>
                    <TitleAndDates>
                        Senior Software Engineer, June 2017 - Present
                    </TitleAndDates>
                    <TitleAndDates>
                        Software Engineer, January 2016 - June 2017
                    </TitleAndDates>
                    <TitleAndDates>
                        Software Engineer Intern, June 2015 - August 2015
                    </TitleAndDates>
                    <AdditionalInfo>
                        - Led the integration with our third-party ad server
                        using Scala -- a company second. System was responsible
                        for syncing our domain with the third party, serving
                        ads, and keeping track of attributions.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Refactored the 3rd party domain syncing logic to be
                        more resilient to failure and handle the increased load
                        as our product grew. Allowed large practices with many
                        locations to be synced even if they required numerous
                        runs and syncing no longer impacted ad serving.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Led the redesign and implementation of the
                        doctor-facing dashboard as well as the back-end
                        improvements to support the new app. Load times were
                        decreased by up to 2 orders of magnitude. The dashboard
                        was built in React.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Collaborated with our telehealth team for the
                        implementation of their pilot. I was responsible for
                        integrating with our ad service. I also assisted them in
                        spinning up their backend service -- setting up their
                        infrastructure and creating the boilerplate Scala server
                        for which they would build on. I also put together and
                        deployed the skeleton of their front-end app.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Implemented a C# framework responsible for flushing
                        request lifetime events to Kinesis Firehose. This
                        framework is used by numerous teams for collecting
                        events and data to feed our data science pipelines.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Led an effort in improving our teams Operation
                        Excellence. The result of this effort was alignment
                        around on call best practices, improved monitoring and
                        alerting, blue-green implementation for our service
                        which enabled us to recover from failure in under 5
                        minutes.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Co-led the Scala guild -- a biweekly meetup to
                        discuss, mentor, or share Scala insights so that
                        engineers learning Scala have a means by which to
                        improve and to align the organization&apos;s best
                        practices in Scala.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Created an internal sbt plugin similar to the node-js
                        Semantic Release lib so that our Scala libs and services
                        followed similar versioning semantics as our javascript
                        ones.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Implemented an HTTP client library to be used by all
                        internal services that use Scala. This library ensures
                        that we have proper tracing, logging, retrying, and
                        circuit breaking.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Created a macro library for auto-generating code at
                        compile time to reduce the amount of boilerplate code
                        necessary when using Slick, a third party ORM library.
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Other responsibilities included: Interviewing
                        candidates, mentoring interns, introducing the intern
                        class to Scala, mentoring new teammates, being on-call
                        for any issues that arise off-hours.
                    </AdditionalInfo>
                </ResumeSectionSubsection>
                <ResumeSectionSubsection isMobile={isMobile}>
                    <ResumeSectionSubsectionHeader>
                        Barracuda Technical Solutions
                    </ResumeSectionSubsectionHeader>
                    <TitleAndDates>
                        .NET Web Developer, February 2014 - January 2016
                    </TitleAndDates>
                    <AdditionalInfo>
                        - Implemented notification system for Power Equipment
                        Auctions to notify and update prices in real-time while
                        users browse an auction
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Implemented shopping cart functionality and back end
                        for Honda Power Equipment Parts
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Implemented the ability to import and create rosters
                        for fantasy football and baseball teams for ScoutPro
                    </AdditionalInfo>
                    <AdditionalInfo>
                        - Implemented the front and back-end for Admin portion
                        of Honda Power Equipment Auctions site, allowing admins
                        to manage existing auctions, products, and users and to
                        upload/create new auctions
                    </AdditionalInfo>
                </ResumeSectionSubsection>
            </ResumeSection>
            <ResumeSection>
                <ResumeSectionHeader>Skills</ResumeSectionHeader>
                <ResumeSectionSubsection>
                    <AdditionalInfo>
                        Languages: Scala, C#, Javascript, Java, SQL, MySQL{' '}
                        <br /> {isMobile && <br />}
                        Frameworks, Tools, Services: Finatra, React, .NET,
                        Docker, Ansible, AWS
                    </AdditionalInfo>
                </ResumeSectionSubsection>
            </ResumeSection>
        </FileViewer>
    );
};

const ResumeHeader = styled.div`
    text-align: center;
`;

const Name = styled.div`
    font-size: 32px;
`;

const ResumeSubheader = styled.div`
    font-weight: 400;
`;

const ResumeSection = styled.div`
    margin-top: 16px;
`;

const ResumeSectionHeader = styled.div`
    width: fit-content;

    padding: 2px 16px;

    background-color: white;
    color: ${colors.blueCharade};
    font-size: 32px;
`;

const ResumeSectionSubsection = styled.div`
    margin-top: 16px;
    padding-left: 32px;

    ${(p) =>
        p.isMobile ? 'padding: 0 24px 0 24px' : 'padding: 0 32px 0 32px'};
`;

const ResumeSectionSubsectionHeader = styled.div`
    font-size: 24px;
    padding-bottom: 4px;
`;

const TitleAndDates = styled.div`
    font-weight: 400;
    font-style: italic;

    padding-bottom: 2px;
`;

const AdditionalInfo = styled.div`
    margin-top: 4px;
    padding-bottom: 2px;
    line-height: 1.15;
`;

const Mushroom = styled.span`
    font-size: 20px;
`;

export default Resume;
