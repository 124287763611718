export const findFile = ({ path, root, cwd }) => {
    let dirArr;
    let file;

    if (path[0][0] === '/') {
        // is absolute
        dirArr = path[0].substring(1).split('/');
        file = root;
    } else {
        dirArr = path[0].split('/');
        file = cwd;
    }

    while (dirArr.length > 0 && path[0] !== '/') {
        const elem = dirArr.shift();

        switch (elem) {
            case '..':
                file = file.parent() || file;
                break;
            case '.':
                break;
            default: {
                file = file.children().find((c) => c.name === elem);

                if (!file) return file;
            }
        }
    }

    return file;
};

export const buildTrie = ({ currentFile, currentNode = null }) => {
    if (currentNode === null) {
        return {
            [currentFile.name[0]]: buildTrie({
                currentFile,
                currentNode: {},
            }),
        };
    }

    const fileName = currentFile.name;

    let tmp = currentNode;

    for (let i = 1; i < fileName.length; i++) {
        if (tmp[fileName[i]]) {
            tmp = tmp[fileName[i]];
        } else {
            const newChild = {};
            tmp[fileName[i]] = newChild;
            tmp = newChild;
        }
    }

    tmp.isTerminal = true;

    if (!currentFile.children || currentFile.children().length === 0) {
        return currentNode;
    }

    currentFile.children().forEach((child) => {
        if (tmp[child.name[0]]) {
            return buildTrie({
                currentFile: child,
                currentNode: tmp[child.name[0]],
            });
        }

        tmp[child.name[0]] = buildTrie({
            currentFile: child,
            currentNode: {},
        });
    });

    return currentNode;
};

export const tabCompleteFile = ({ path, root, cwd }) => {
    const currentFile = path[0] === '/' ? root : cwd;

    const trie = currentFile === root ? buildTrie({ currentFile: root }) : {};

    if (currentFile === cwd) {
        currentFile.children().forEach((child) => {
            if (trie[child.name[0]]) {
                return buildTrie({
                    currentFile: child,
                    currentNode: trie[child.name[0]],
                });
            }

            trie[child.name[0]] = buildTrie({
                currentFile: child,
                currentNode: {},
            });
        });
    }

    let currentNode = trie[path[0]];
    if (!currentNode) return path;

    let result = path[0];

    for (let i = 1; i < path.length; i++) {
        const letter = path[i];
        if (!currentNode[letter]) break;

        result += letter;
        if (path[i + 1] === '/' && currentNode[letter].isTerminal) {
            result += '/';
            i += 1;
        }

        currentNode = currentNode[letter];
    }

    if (result.indexOf(path) < 0) return path;

    let nextLetters = Object.keys(currentNode).filter((x) => x.length === 1);
    while (nextLetters.length === 1) {
        result += nextLetters[0];

        currentNode = currentNode[nextLetters[0]];
        if (currentNode.isTerminal) return result;

        nextLetters = Object.keys(currentNode).filter((x) => x.length === 1);
    }

    return result;
};
