import File, { FileTypes } from 'files/File';
import root from '..';
import moldy530 from './moldy530';

export default new File({
    fileType: FileTypes.DIRECTORY,
    name: 'Users',
    parent: () => root,
    children: () => [moldy530],
});
