import React, { useState, useCallback } from 'react';
import moldy530 from 'files/root/Users/moldy530';
import root from 'files/root';
import Prompt from 'components/Terminal/Prompt';
import CommandRunnner from 'components/Terminal/CommandRunner';
import useStdOut from 'hooks/StdOut/useStdOut';

export default (active) => {
    const [history, setHistory] = useState([]);
    const [cwd, setCwd] = useState(moldy530);
    const { stdOut, print, clear } = useStdOut();

    const printNewPrompt = useCallback(() => print(<Prompt />), [print]);
    const printCommandRunner = useCallback(
        (input) => print(<CommandRunnner input={input} />),
        [print]
    );

    return {
        clear,
        cwd,
        history,
        print,
        printCommandRunner,
        printNewPrompt,
        setCwd,
        setHistory,
        stdOut,
        fsRoot: root,
        terminalActive: active,
    };
};
