import styled from 'styled-components';
import { colors } from 'styleguide';

const UsageView = ({ usage }) => (
    <UsageContainer>
        <div>usage:&nbsp;</div>
        <div>
            {usage.map((u, i) => (
                <Usage key={i}> {u} </Usage>
            ))}
        </div>
    </UsageContainer>
);

const ManPage = ({ name, description, usage }) => (
    <Container>
        <Name> {name} </Name>
        <Description>
            description:&nbsp;
            {description}
        </Description>
        {usage && <UsageView usage={usage} />}
    </Container>
);

const Container = styled.div`
    display: flex;
    flex-direction: column;

    margin-left: 12px;
    margin-top: 16px;
    margin-bottom: 24px;
`;

const Description = styled.div`
    margin-bottom: 4px;

    color: ${colors.frenchGray};
`;

const Name = styled.div`
    color: ${colors.blueMalibu};
    margin-bottom: 4px;
`;

const UsageContainer = styled.div`
    display: flex;

    color: ${colors.frenchGray};
`;

const Usage = styled.div`
    margin-left: 4px;
    padding-bottom: 4px;
`;

export default ManPage;
