import TerminalContext from 'context/Terminal';
import { useCallback, useContext, useState } from 'react';
import { keyCodes } from '../constants';

import useInputWithCursor from '../InputWithCursor/useInputWithCursor';

import { tabCompleteFile } from 'programs/utils/file-utils';
import findCommand from 'programs/utils/find-command';
import useKeydown from '../KeyEvent/useKeydown';

export default ({ isActive, setIsActive }) => {
    const { history, printCommandRunner, cwd, fsRoot } =
        useContext(TerminalContext);

    const [historyLocation, setHistoryLocation] = useState(history.length);
    const { cursorPosition, inputs, updateInputs, mobileInputHandler } =
        useInputWithCursor({
            isActive,
            handleSubmit: () => {
                setIsActive(false);

                printCommandRunner(inputs.join(''));
            },
        });

    // Key Handlers
    const handleKey = (keys, handler) => (key, evt) => {
        if ((keys.has && keys.has(key)) || keys === key) handler(key, evt);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpDown = useCallback(
        handleKey(new Set().add(keyCodes.UP).add(keyCodes.DOWN), (key, evt) => {
            evt.preventDefault();
            const direction = key - keyCodes.RIGHT;
            const historyIdx = Math.max(historyLocation + direction, 0);

            setHistoryLocation(Math.min(historyIdx, history.length));

            if (historyIdx === history.length) {
                updateInputs([]);
            } else {
                const historyItem = history[historyIdx] || '';
                updateInputs(historyItem.split(''));
            }
        }),
        [historyLocation, history, updateInputs]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTab = useCallback(
        handleKey(new Set().add(keyCodes.TAB), (key, evt) => {
            evt.preventDefault();
            const isDotSlash = inputs.join('').indexOf('./') === 0;
            if (isDotSlash) {
                const tabResult = tabCompleteFile({
                    path: inputs.slice(2).join(''),
                    root: fsRoot,
                    cwd,
                });

                return updateInputs(
                    inputs.slice(0, 2).concat(tabResult.split(''))
                );
            }

            const idxSpace = inputs.indexOf(' ');
            if (idxSpace >= 0) {
                const tabResult = tabCompleteFile({
                    path: inputs.slice(idxSpace + 1).join(''),
                    root: fsRoot,
                    cwd,
                });

                return updateInputs(
                    inputs.slice(0, idxSpace + 1).concat(tabResult.split(''))
                );
            }

            updateInputs(findCommand(inputs));
        }),
        [inputs, updateInputs]
    );

    const keydownHandler = useCallback(
        (e) => {
            const key = e.charCode || e.keyCode;

            handleUpDown(key, e);
            handleTab(key, e);
        },
        [handleUpDown, handleTab]
    );

    useKeydown(keydownHandler, isActive);

    return {
        cursorPosition,
        inputs,
        mobileInputHandler,
    };
};
