import React from 'react';

import useUser from 'hooks/User/useUser';
import CommandOutputContainer from 'components/CommandOutputContainer';

export default () => {
    const { user } = useUser();

    return (
        <CommandOutputContainer>
            {(user && user.email) || 'no one'}
        </CommandOutputContainer>
    );
};
