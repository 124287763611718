import File, { FileTypes } from 'files/File';
import Users from '..';
import Developer from './Developer';
import Photographer from './Photographer';

export default new File({
    fileType: FileTypes.DIRECTORY,
    name: 'moldy530',
    parent: () => Users,
    children: () => [Developer, Photographer],
});
