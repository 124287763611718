/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { colors } from 'styleguide';
import FileViewer from '../FileViewer';

const CollapseableRow = ({ data, parentChecked }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [checked, setChecked] = useState(!!parentChecked);

    useEffect(() => {
        setChecked(parentChecked);
    }, [parentChecked]);

    const keys = Object.keys(data).filter((x) => x !== 'children');
    const childHeaders =
        data.children &&
        Object.keys(data.children[0]).filter((x) => x !== 'children');

    return (
        <React.Fragment>
            <tr>
                <FixedTd>
                    <CheckboxContainer>
                        <input
                            checked={!!checked}
                            onChange={() => setChecked((c) => !c)}
                            type="checkbox"
                        />
                        {childHeaders && (
                            <DropdownArrow
                                onClick={() => setCollapsed((c) => !c)}
                                role="button"
                            >
                                {collapsed ? <span>→</span> : <span>↓</span>}
                            </DropdownArrow>
                        )}
                    </CheckboxContainer>
                </FixedTd>
                {keys.map((k) => (
                    <StyledTd key={k}>{data[k]}</StyledTd>
                ))}
            </tr>
            {!collapsed && childHeaders && (
                <tr>
                    <TableContainerTd />
                    <TableContainerTd colSpan={keys.length}>
                        <ChildTable>
                            <StyledThead>
                                <tr>
                                    <FixedTh />
                                    {childHeaders.map((ch) => (
                                        <StyledTh key={ch}>{ch}</StyledTh>
                                    ))}
                                </tr>
                            </StyledThead>
                            <tbody>
                                {data.children.map((child) => (
                                    <CollapseableRow
                                        key={child.id}
                                        data={child}
                                        parentChecked={checked}
                                    />
                                ))}
                            </tbody>
                        </ChildTable>
                    </TableContainerTd>
                </tr>
            )}
        </React.Fragment>
    );
};

const TreeTable = ({ returnToTerminal }) => {
    const data = [
        {
            id: 10,
            'strategic name': 'a strategic',
            children: [
                {
                    id: 30,
                    'practice name': 'a practice',
                    additional: 'spo enabled',
                    children: [
                        {
                            id: 1234,
                            name: 'Dr. John Smith',
                            specialty: 'PCP',
                        },
                        {
                            id: 5678,
                            name: 'Dr. Moldy UEM',
                            specialty: 'Cardiologist',
                        },
                    ],
                },
            ],
        },
        {
            id: 12,
            'strategic name': 'another strategic',
            children: [
                {
                    id: 55,
                    'practice name': 'another practice',
                    additional: 'spo enabled',
                },
            ],
        },
    ];

    const headers = Object.keys(data[0]).filter((x) => x !== 'children');

    return (
        <FileViewer
            fileName="TreeTable.sample"
            returnToTerminal={returnToTerminal}
        >
            <TableContainer>
                <StyledTable>
                    <StyledThead>
                        <tr>
                            <FixedTh />
                            {headers.map((h) => (
                                <StyledTh key={h}>{h}</StyledTh>
                            ))}
                        </tr>
                    </StyledThead>
                    <tbody>
                        {data.map((d) => (
                            <CollapseableRow key={d.id} data={d} />
                        ))}
                    </tbody>
                </StyledTable>
            </TableContainer>
        </FileViewer>
    );
};

const TableContainer = styled.div`
    flex: 1;
    width: 100%;
`;

const ChildTable = styled.table`
    width: 100%;
    table-layout: fixed;
`;

const StyledTable = styled.table`
    border-collapse: collapse;
    border: 1px solid white;

    width: 100%;
`;

const StyledTd = styled.td`
    border: 1px solid white;
    padding: 8px 0;

    text-align: center;
`;

const TableContainerTd = styled(StyledTd)`
    border: 0;
`;

const FixedTd = styled(StyledTd)`
    width: 60px;
`;

const StyledTh = styled.th`
    border: 1px solid white;
    padding: 4px 0;
`;

const FixedTh = styled(StyledTh)`
    width: 60px;
`;

const StyledThead = styled.thead`
    background-color: ${colors.purpleBaliHai};
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DropdownArrow = styled.div`
    margin-left: 8px;
`;

export default TreeTable;
