import { useContext, useState } from 'react';
import styled from 'styled-components';

import authenticateRH from 'api/sweater/authenticate-rh';

import CommandOutputContainer from 'components/CommandOutputContainer';
import Error from 'components/ErrorText';
import MobileInput from 'components/MobileInput';
import Success from 'components/SuccessText';
import TextWithCursor from 'components/TextWithCursor';
import Password from 'components/User/Password';

import AppContext from 'context/App';
import CommandContext from 'context/Command';

import useInputWithCursor from 'hooks/InputWithCursor/useInputWithCursor';
import useKeydown from 'hooks/KeyEvent/useKeydown';
import { keyCodes } from 'hooks/constants';

import { colors } from 'styleguide';

const ChallengeTypePicker = ({
    challengeTypes,
    onChallengeSelected,
    enabled,
}) => {
    const [selected, setSelected] = useState(0);
    const challengeTypesArr = Object.keys(challengeTypes);

    useKeydown((e) => {
        if (!enabled) return;

        const key = e.charCode || e.keyCode;

        if (key === keyCodes.LEFT) {
            setSelected((x) => Math.min(x + 1, challengeTypesArr.length - 1));
        }

        if (key === keyCodes.RIGHT) {
            setSelected((x) => Math.max(x - 1, 0));
        }

        if (key === keyCodes.ENTER) {
            onChallengeSelected(challengeTypesArr[selected]);
        }
    }, enabled);

    return (
        <ChallengePickerContainer>
            <div style={{ marginRight: '4px' }}>select challenge type: </div>
            {challengeTypesArr.map((challenge, idx) => (
                <ChallengeType
                    selected={idx === selected}
                    onClick={() => {
                        setSelected(idx);
                        onChallengeSelected(challengeTypesArr(idx));
                    }}
                >
                    {challenge}
                </ChallengeType>
            ))}
        </ChallengePickerContainer>
    );
};

const ChallengePickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ChallengeType = styled.div`
    background-color: ${(p) => (p.selected ? colors.greyMineShaft : 'none')};
    padding: 2px 4px 4px 4px;
`;

const ChallengeInput = ({ onChallengeInputed, running }) => {
    const [active, setActive] = useState(true);
    const { inputs, mobileInputHandler, cursorPosition } = useInputWithCursor({
        isActive: active && running,
        handleSubmit: () => {
            setActive(false);
            onChallengeInputed(inputs.join(''));
        },
    });

    return (
        <Container>
            input mfa code:
            <TextWithCursor
                isActive={active && running}
                text={inputs.join('')}
                cursorPosition={cursorPosition}
            />
            {window.mobilecheck() && (
                <MobileInput
                    type="password"
                    inputs={inputs}
                    isActive={active && running}
                    onChange={mobileInputHandler}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    padding-bottom: 8px;
`;

const Login = ({ user }) => {
    const { running, print, exit } = useContext(CommandContext);
    const { deviceToken } = useContext(AppContext);

    const [password, setPassword] = useState(undefined);
    const [challengeTypes, setChallengeTypes] = useState(undefined);
    const [challengeId, setChallengeId] = useState(undefined);
    const [challengeType, setChallengeType] = useState(undefined);

    const callRHAuth = ({ pw, challenge_type, challenge }) => {
        authenticateRH({
            email: user,
            password: password || pw,
            challenge_type: challengeType || challenge_type,
            challenge_id: challengeId,
            device_token: deviceToken,
            mfa: challenge,
        })
            .then(({ accept_challenge_types, challenge }) => {
                if (accept_challenge_types) {
                    setChallengeTypes(accept_challenge_types);
                    return;
                }

                if (challenge) {
                    setChallengeId(challenge.id);
                    return;
                }

                print(<Success>connected to robinhood</Success>);
                exit();
            })
            .catch(() => {
                print(<Error>failed to authenticate with RH</Error>);
                exit();
            });
    };

    const onPasswordSet = (password) => {
        setPassword(password);
        callRHAuth({ pw: password });
    };

    const onSetChallengeType = (challengeType) => {
        setChallengeType(challengeType);
        callRHAuth({ challenge_type: challengeType });
    };

    const onChallengeInputed = (challenge) => {
        callRHAuth({ challenge });
    };

    return (
        <CommandOutputContainer>
            <Password setPassword={onPasswordSet} />
            {challengeTypes && (
                <ChallengeTypePicker
                    enabled={!challengeId && running}
                    challengeTypes={challengeTypes}
                    onChallengeSelected={onSetChallengeType}
                />
            )}
            {challengeId && (
                <ChallengeInput
                    onChallengeInputed={onChallengeInputed}
                    running={running}
                />
            )}
        </CommandOutputContainer>
    );
};

export default Login;
