export default ({ id, quality, type, user }) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/movies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: 'include',
        body: JSON.stringify({
            email: user.email,
            id,
            quality,
            type,
        }),
    }).then((res) => {
        if (!res.ok) throw new Error('failed to record selection!');
    });
};
