const googleImageUrl = (id, isThumbnail = true, width = 25, quality = 20) => {
    const host =
        process.env.REACT_APP_CDN_URL ||
        process.env.REACT_APP_API_HOST ||
        'http://localhost:5000/prod-moldy-sh/us-central1';
    const url = (imgId) =>
        `${host}/v1/photos${
            isThumbnail ? '/thumbnail' : ''
        }?id=${imgId}&width=${width}&quality=${quality}`;

    if (id.indexOf('http') < 0) {
        return url(id);
    }

    const params = new URLSearchParams(id.substring(id.indexOf('?')));

    return url(params.get('id'));
};

module.exports = googleImageUrl;
