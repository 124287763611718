import commands from '../index';

const commandKeys = Object.keys(commands);

const root = {};

for (let i = 0; i < commandKeys.length; i++) {
    const commandlets = commandKeys[i].split('');
    let currentNode = root;

    for (let j = 0; j < commandlets.length; j++) {
        const currentLetter = commandlets[j];
        if (currentNode[currentLetter]) {
            currentNode = currentNode[currentLetter];
        } else {
            currentNode[currentLetter] = {};
            currentNode = currentNode[currentLetter];
        }
    }
    currentNode.terminal = true;
}

// if no command just return input
// if command: return input + letters of tree until terminal OR until more than one child
const findCommand = (input) => {
    const result = [];
    let currentNode = root;

    for (let i = 0; i < input.length; i++) {
        const letter = input[i];
        if (currentNode[letter]) {
            result.push(letter);
            currentNode = currentNode[letter];
        } else {
            break;
        }
    }
    // if we didn't make it through the word and found stuff, then we return
    if (result.join('').indexOf(input.join('')) < 0) return input;

    // go through the tree until we terminate or hit more than one kids

    // eslint-disable-next-line no-constant-condition
    while (true) {
        if (Object.keys(currentNode).length > 1) return result;
        if (currentNode.terminal) {
            result.push(' ');
            return result;
        }

        const letter = Object.keys(currentNode)[0];
        result.push(letter);
        currentNode = currentNode[letter];
    }
};

export default findCommand;
