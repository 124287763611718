import React from 'react';

import parseUser from './utils/parse-user';
import trustUser from 'api/trust-user';

import Success from 'components/SuccessText';
import Error from 'components/ErrorText';

const trustuser = {
    run: ({ input, print, exit, user }) => {
        const userEmail = parseUser({
            input,
            command: 'trust-user',
            print,
            exit,
        });

        if (!userEmail) return exit();

        return trustUser({
            email: userEmail,
            token: user.token,
        })
            .then(() => {
                print(<Success>added {userEmail} as trusted</Success>);
                exit();
            })
            .catch(() => {
                print(<Error>failed to trust user</Error>);
                exit();
            });
    },
};

export default trustuser;
