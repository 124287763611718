import { createGlobalStyle } from 'styled-components';
import { colors } from 'styleguide';

/*
 * CSS Reset
 *
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126
 * License: none (public domain)
 */
export const DefaultGlobals = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    a {
        color: ${colors.purpleBaliHai};
        text-decoration: inherit;

        &:hover{
            text-decoration: underline;
        }
    }
    button {
        border-radius: 0;
    }
    [hidden] {
      display: none !important;
    }
`;

/*
 * fonts
 */
export const DefaultFonts = createGlobalStyle`
    /* latin-ext */
    @font-face {
      font-family: 'Roboto mono', monospace;
      font-style: normal;
      font-weight: 900;
      src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Roboto mono', monospace;
      font-style: normal;
      font-weight: 900;
      src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
`;

/*
 * default styles
 * + fixing the FF extra button spacing
 */
export const DefaultStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body #root {
        height: 100vh;
    }

    body {
        background-color: white;
        font-family: 'Roboto mono', monospace;
        color: black;
        line-height: 1;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: normal;
        margin: 0;
        text-rendering: optimizeLegibility;
    }
    body.disableScroll {
        overflow: hidden;
    }
    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
    img {
        // Grid defaults to get images and embeds to work properly
        max-width: 100%;
        height: auto;
        -ms-interpolation-mode: bicubic;
        // Get rid of gap under images by making them display: inline-block; by default
        display: inline-block;
        vertical-align: middle;
    }
    main {
        display: block; // IE
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }
    h1, h2, h3 {
        font-family: 'Roboto mono', monospace;
        font-weight: 900;
        font-style: normal;
        line-height: 1.1em;
        letter-spacing: .78em;
        text-transform: uppercase;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-family: 'Roboto mono', monospace;
        font-size: 20px;
        &-emphasized {
            font-family: 'Roboto mono', monospace;
            font-size: 20px;
        }
    }
    label {
        font-family: 'Roboto mono', monospace;
        font-size: 16px;
    }
    p {
        font-family: 'Roboto mono', monospace;
    }
    span {
        font-family: 'Roboto mono', monospace;
    }

    i {
        font-family: 'Roboto mono', monospace;
        font-style: italic;
    }
`;
