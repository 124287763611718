import styled from 'styled-components';

import { FileTypes } from 'files/File';
import { colors } from 'styleguide';

const DirectoryList = ({ cwdChildren }) => (
    <Container>
        <Entry fileType={FileTypes.DIRECTORY}>.</Entry>
        <Entry fileType={FileTypes.DIRECTORY}>..</Entry>
        {cwdChildren.map((c) => (
            <Entry
                key={c.name}
                fileType={c.file_type}
                executable={c.executable}
            >
                {c.name}
            </Entry>
        ))}
    </Container>
);

const Container = styled.div`
    margin-left: 12px;
    display: flex;
`;

const Entry = styled.div`
    margin-right: 24px;
    color: ${(p) =>
        p.fileType === FileTypes.DIRECTORY
            ? colors.blueMalibu
            : p.executable
            ? colors.greenExecutable
            : 'white'};
`;

export default DirectoryList;
