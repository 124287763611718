export const colors = {
    yellowPicasso: '#FFEF03',

    green: '#369c39',
    greenExecutable: '#00e500',

    brightTurquoise: '#00E5E5',
    blueMalibu: '#80A8FB',
    purpleBaliHai: '#9198b7',

    redFroly: '#f07178',

    frenchGray: '#C9C9CB',
    greyMineShaft: '#3f3f3f',
    blueCharade: '#0f111a',
};

export const sizes = {
    promptTextEm: 0.6,
    promptTextPx: 16,
};

export const breakpoints = {
    MOBILE: 767,
    TABLET: 1023,
};

const mediaQueryWidths = {
    MOBILE: `(max-width: ${breakpoints.MOBILE}px)`,
    MOBILE_AND_TABLET_PORTRAIT: `(max-width: ${breakpoints.MOBILE + 1}px)`,
    TABLET: `(max-width: ${breakpoints.TABLET}px) and (min-width: ${
        breakpoints.MOBILE + 1
    }px)`,
    TABLET_AND_MOBILE: `(max-width: ${breakpoints.TABLET}px)`,
};

const generateMediaQueryForBreakpoint =
    (breakpoint) =>
    (...body) => {
        const stringBody = body[0];
        let resultString = '';

        for (let i = 0; i < stringBody.length; i++) {
            const nextArg = body[i + 1] || '';

            resultString +=
                stringBody[i] +
                (typeof nextArg === 'function' ? nextArg() : nextArg);
        }

        return `@media ${breakpoint} {
            ${resultString}
        }`;
    };

export const mediaQueries = {
    mobile: generateMediaQueryForBreakpoint(mediaQueryWidths.MOBILE),
    mobileAndTabletPortrait: generateMediaQueryForBreakpoint(
        mediaQueryWidths.MOBILE_AND_TABLET_PORTRAIT
    ),
    tablet: generateMediaQueryForBreakpoint(mediaQueryWidths.TABLET),
    tabletAndMobile: generateMediaQueryForBreakpoint(
        mediaQueryWidths.TABLET_AND_MOBILE
    ),
    customMax: (max) =>
        generateMediaQueryForBreakpoint(`(max-width: ${max}px)`),
};
