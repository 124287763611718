export default ({ searchTerms }) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';

    return fetch(`${host}/v1/search?query=${searchTerms}`, {
        method: 'GET',
    }).then((resp) => {
        if (resp.ok) return resp.json();

        throw new Error('search failed');
    });
};
