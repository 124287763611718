import cookies from 'js-cookie';
import { COOKIES } from 'constants';

export default ({ device_token }) => {
    const host = process.env.REACT_APP_API_HOST || 'http://localhost:4000';
    const access_token = cookies.get(COOKIES.RH_ACCESS_TOKEN);

    if (!access_token) throw new Error('need to authenticate first.');

    return fetch(`${host}/v1/sweater/stream`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
            device_token,
        }),
    }).then((res) => {
        if (!res.ok) throw new Error('failed to start stream!');

        return;
    });
};
